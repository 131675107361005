import React, { useEffect, useRef, useState } from "react";

import { scroller } from "react-scroll";

import Styles from "./Home.module.css";
import CommonCss from "../../Styles/CommonStyle.module.css";
import Header from "../../../Sections/Header/Header";
import OurServiceCard from "../../../Components/OurServiceCard/OurServiceCard";
import WhyChooseCard from "../../../Components/WhyChooseCard/WhyChooseCard";
import FactDisplaySection from "../../../Components/FactDisplaySection/FactDisplaySection";
import HomesectorLandingTop from "../../../Sections/LandingTop/homesector/HomesectorLandingTop";
import Footer from "../../../Sections/Footer/Footer";
import GetStarted from "../../../Sections/GetStarted/GetStarted";
import AboutUs from "../../../Sections/AboutUs/AboutUs";
import OurProcess from "../../../Sections/OurProcess/OurProcess";
import FAQsList from "../../../Sections/FAQs/FAQsList";
import { HomeServiceFAQ } from "../../../Sections/FAQs/FAQsListData";
import { AboutOpportunitiesHomeService } from "../../../Sections/AboutOpportunities/AboutOpportunitiesData";
import AboutOpportunities from "../../../Sections/AboutOpportunities/AboutOpportunities";
import { HomeServicesOurProcessData } from "../../../Sections/OurProcess/OurProcessData";

const HomeServicesLanding = () => {
  const [activeSection, setActiveSection] = useState("section1");

  const sectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      const domNode = ref.current;
      // console.log(domNode, " ref.current;");
      if (domNode) {
        observer.observe(domNode);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        const domNode = ref.current;
        if (domNode) {
          observer.unobserve(domNode);
        }
      });
    };
  }, [sectionRefs]);

  const handleNavClick = (section) => {
    // console.log(section);
    scroller.scrollTo(section, {
      duration: 100,
      delay: 0,
      smooth: "linear",
      offset: -50,
    });
  };

  return (
    <>
      {/* --------------------------------------Header----------------------------------------------- */}

      <Header
        activeSection={activeSection}
        handleNavClick={handleNavClick}
        primaryColor={"linear-gradient(0deg, #706EE6 0%, #706EE6 100%), #000"}
        secondaryColor={"#fff"}
        backgroundColor={
          "linear-gradient(0deg, rgba(72, 207, 175, 0.50) 0%, rgba(72, 207, 175, 0.50) 100%), #F8F9FF"
        }
      />

      {/* -------------------------------------Landing Top-------------------------------------- */}

      <section id="section1" ref={sectionRefs.section1}>
        <HomesectorLandingTop
          bgColor={`linear-gradient(180deg, rgba(72, 207, 175, 0.50) 0%, rgba(65, 193, 201, 0.00) 100%)`}
          heading={`Experience Top-Notch Home Services with CONNECT`}
          headingColor="#04033E"
          para={`Professional, Reliable, and Convenient Solutions Tailored to Your
              Needs – All at Your Doorstep`}
          joinBtnC1={"#706EE6"}
          joinBtnC2={"rgba(112, 110, 230, 0.10)"}
          image={"/Assets/heroImages/homeServiceHero.webp"}
          bgImage={`url('/Assets/BackgroundImages/HomeServiceBackgroundImage.svg')`}
        />
      </section>

      {/* --------------------------------Fact display bar --------------------------------------------- */}

      <section>
        <FactDisplaySection
          color={"#fff"}
          backGroundColor={"linear-gradient(91deg, #48CFAF 0%, #41C1C9 100%)"}
          otherColor={"#706EE6"}
          lineColor={"#FCFCFC"}
          fact1={{ figure: 20, title: "Management Team" }}
          fact2={{ figure: 50, title: "Certified Electrician" }}
          fact3={{ figure: 80, title: "Support Staff" }}
          fact4={{ figure: 15, title: "Assistants" }}
        />
      </section>

      {/* ---------------------------------------------About Us ----------------------------------------- */}

      <section
        className={CommonCss.section_top1}
        id="section2"
        ref={sectionRefs.section2}
      >
        <AboutUs
          data={[
            {
              image: "/Assets/HomeServices/Images/img2.webp",
              headingColor: "#04033E",
              info: [
                {
                  heading: "About us",
                  content: [
                    "At CONNECT, we specialize in delivering top-notch services right to your doorstep. Whether you need a yoga instructor, daily cooking, tailoring, gardening, driving, astrology, tutoring, cleaning, dry cleaning, electrical repairs, elderly care, or maid services, we have you covered. Our mission is to simplify your life by providing professional, reliable, and convenient services tailored to your needs. Enjoy the comfort of expert assistance without stepping out of your home.",
                  ],
                },
              ],
            },
          ]}
        />
      </section>

      {/* -----------------------------------------Our Services-------------------------------------------- */}

      <section
        className={Styles.section_top2}
        id="section3"
        ref={sectionRefs.section3}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Services
          </h2>
          <p className={CommonCss.section_subHeading}>
            At CONNECT, we are dedicated to making your daily life easier and
            more enjoyable by providing a wide range of high-quality services
            tailored to your needs. Here’s what we offer:
          </p>

          <div className={CommonCss.containerSection}>
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img3.webp"}
              heading={"Instructor Services"}
              content={
                "  Personalized fitness training, expert yoga sessions for physical and mental well-being, and fun Zumba classes to keep you active and fit."
              }
            />

            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img4.webp"}
              heading={"Cook Services"}
              content={
                "Enjoy home-cooked meals for special occasions or daily,tailored to your dietary preferences."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img5.webp"}
              heading={"Maid Services"}
              content={
                "Reliable and thorough cleaning services to keep your home spotless."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img6.webp"}
              heading={"Elderly Caregivers"}
              content={
                "Compassionate and professional care for your loved ones, ensuring their comfort and safety."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img7.webp"}
              heading={"Dry Cleaners"}
              content={
                " Convenient and efficient dry cleaning services for your garments and linens."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img8.webp"}
              heading={"Electrician"}
              content={
                "Skilled electricians for all your electrical repair and installation needs."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img9.webp"}
              heading={"Cleaning Specialist"}
              content={
                " Deep cleaning services for homes and offices, ensuring hygienic environment."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img10.webp"}
              heading={"Gardener"}
              content={
                " Expert gardening services to keep your outdoor spaces beautiful and well-maintained."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img11.webp"}
              heading={"Tailor"}
              content={
                " Custom tailoring services for perfectly fitted clothing and alterations."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img12.webp"}
              heading={"Driver"}
              content={
                "Professional and reliable driving services for your daily commutes and errands."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/img13.webp"}
              heading={"Tutor"}
              content={
                "Personalized tutoring for academic subjects and painting lessons to nurture creativity."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/HomeServices/Images/bulb.svg"}
              heading={"Astrologer"}
              content={
                "Insightful astrological consultations to guide you in making informed life decisions."
              }
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------Why to choose us--------------------------------------------- */}

      <section
        className={CommonCss.section_top3}
        id="section4"
        ref={sectionRefs.section4}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Why Choose us
          </h2>
          <h3 className={CommonCss.section_subHeading2}>
            Welcome to CONNECT - Your Trusted Home Help Partner
          </h3>
          <p className={CommonCss.section_subHeading}>
            At CONNECT, we understand that life can get busy, and maintaining a
            clean and organized home can sometimes feel overwhelming. That's
            where we come in. With our professional home help services, we
            strive to make your life easier and your home happier.
          </p>
          <div className={CommonCss.containerSection}>
            <WhyChooseCard
              imageSource={"/Assets/HomeServices/Images/img15.png"}
              heading={"Experties"}
              width={"350px"}
              content={
                " Our team consists of skilled professionals who are trained and experienced in a wide range of home maintenance and cleaning tasks. From deep cleaning to organizing, we've got the expertise to handle it all."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/HomeServices/Images/img16.png"}
              heading={"Convenience"}
              width={"350px"}
              content={
                "We know your time is precious. That's why we offer flexible scheduling options to fit your busy lifestyle. Whether you need a one-time deep clean or regular maintenance, we'll work around your schedule to ensur your home is always looking its best."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/HomeServices/Images/img17.png"}
              heading={"Reliability"}
              width={"350px"}
              content={
                "When you choose CONNECT, you can trust that we'll always deliver top-quality service. We take pride in our reliability and attention to detail, ensuring that every corner of your home is sparkling clean and well-maintained."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/HomeServices/Images/img18.png"}
              heading={"Personalized Service"}
              width={"350px"}
              content={
                "We understand that every home is unique, which is why we offer personalized service tailored to your specific needs and preferences. Whether you have pets, allergies, or specific cleaning requirements, we'll customize our services to meet your needs."
              }
            />

            <WhyChooseCard
              imageSource={"/Assets/HomeServices/Images/img19.png"}
              heading={"Peace of mind"}
              width={"350px"}
              content={
                " With CONNECT, you can enjoy peace of mind knowing that your home is in good hands. Our professional team is fully vetted and insured, giving you the confidence and assurance you deserve."
              }
            />
          </div>
        </div>
      </section>

      {/* ----------------------------------------------about opportunity---------------------------------- */}

      <section
        className={Styles.section_top4}
        id="section5"
        ref={sectionRefs.section5}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            About Opportunities
          </h2>
          <p className={CommonCss.section_subHeading}>
            At CONNECT, we offer a variety of services to make your life easier
            and more convenient. From personalized fitness and wellness plans to
            reliable home management, expert maintenance, and professional
            tutoring, our comprehensive services provide you with the support
            you need to enjoy a more balanced and stress-free lifestyle.
            Discover how we can enhance your daily routine and bring ease to
            your life.
          </p>
          <AboutOpportunities data={AboutOpportunitiesHomeService} />
        </div>
      </section>

      {/* -------------------------------------Our Process-------------------------------------------- */}

      <section
        className={CommonCss.section_top7}
        id="section6"
        ref={sectionRefs.section6}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Process
          </h2>
          <p className={CommonCss.section_subHeading}>
            Learn about our streamlined process designed to provide you with the
            best home services. From onboarding skilled professionals to
            post-service support, we ensure quality at every step.
          </p>
          <OurProcess data={HomeServicesOurProcessData} />
        </div>
      </section>

      {/* -------------------------------------FAQs-------------------------------------------- */}

      <section
        className={Styles.section_top8}
        id="section7"
        ref={sectionRefs.section7}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            FAQs
          </h2>
          <p className={CommonCss.section_subHeading}>
            Answers to Common Inquiries About Entertainment Services.
          </p>
          <div className="text-center">
            <img
              src="/Assets/HomeServices/Images/img27.webp"
              className={CommonCss.section_top8_img}
              alt="..."
            />
          </div>
          <FAQsList
            color="#fff"
            backgroundColor="#706EE6"
            data={HomeServiceFAQ}
          />
        </div>
      </section>

      {/* -------------------------------------Footer and Get started-------------------------------------------- */}

      <section
        // className={Styles.section_top8}
        id="section8"
        ref={sectionRefs.section8}
      >
        <GetStarted
          heading={"Elevate Your Everyday With Connect Home Services"}
          para={`Experience the convenience and quality of CONNECT Home services,
                designed to enhance your daily life. Contact us today to learn
                more and schedule your service!`}
          image={"/Assets/HomeServices/Images/img28.webp"}
          smallImage={
            "/Assets/HomeServices/Images/homeService_small_enquirenow_img.webp"
          }
          smallImageTop="-44%"
          bgColor={"#4DC2B8"}
          buttonBgColor={"#706EE6"}
          buttonColor={"white"}
        />
        <Footer
          contactColor="#706EE6"
          iconsColor={"white"}
          iconsBgColor="#706EE6"
          handleNavClick={handleNavClick}
        />
      </section>
    </>
  );
};

export default HomeServicesLanding;
