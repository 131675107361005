export const ConstructionFAQ = [
  {
    question: "How do I create an account on your platform?",
    answer:
      'To create an account, click on the "Partner with Us " button on the page. Fill in your details, including your name, email, and essential information. Once you have completed the registration form, click "Submit" to create your account.',
  },
  {
    question:
      "What types of construction professionals can I find on your platform?",
    answer:
      "Our platform offers a wide range of construction professionals, including but not limited to contractors, laborers, engineers, architects, project managers, electricians, plumbers, and carpenters. You can find the right professional for any construction-related task.",
  },
  {
    question: "What should I do if I have an issue with a hired professional?",
    answer:
      'If you encounter any issues with a hired professional, please contact our customer support team for assistance. You can reach us via the "Contact Us" page or through the support chat feature. Our team will help mediate and resolve any disputes to ensure a satisfactory outcome.',
  },
  {
    question: "How do I update my profile information?",
    answer:
      'To update your profile information, log in to your account and go to the "Profile" section. Click on the "Edit Profile" button to update your details such as contact information, skills, experience, and profile picture. Save your changes once you are done.',
  },
  {
    question: "How do I cancel a job or contract with a professional?",
    answer:
      "To cancel a job or contract, communicate your decision directly with the professional as soon as possible. Additionally, inform our customer support team so they can assist with any necessary arrangements or disputes. Please refer to our terms and conditions for specific policies regarding cancellations.",
  },
];

export const EntertainmentFAQ = [
  {
    question: "What is CONNECT and how does it work?",
    answer:
      "CONNECT is a platform designed to connect professionals in the entertainment sector. It allows users to network, find career opportunities, collaborate on projects, and promote events. You can create a profile, share your portfolio, and interact with other industry professionals.",
  },
  {
    question: "Who can join CONNECT?",
    answer:
      "The platform is open to all professionals in the entertainment sector, including actors, musicians, filmmakers, directors, producers, event planners, comedians, and more. Whether you're an experienced industry veteran or just starting your career, you're welcome to join.",
  },
  {
    question: "How do I create a profile?",
    answer:
      "To create a profile, sign up for an account and follow the on-screen instructions. You'll be prompted to enter personal and professional information, upload a profile picture, and add your portfolio. You can also include links to your social media profiles and other relevant information.",
  },
  {
    question: "What can I include in my portfolio?",
    answer:
      "Your portfolio can include images, videos, audio files, and other media that showcase your work. You can also add a biography, list your skills, and highlight past projects or achievements. Make sure your portfolio represents your talents and experience effectively.",
  },
  {
    question: "How do I find opportunities on CONNECT?",
    answer:
      "You can search for opportunities by exploring audition calls, gig listings, and job postings. Use filters to narrow down your search by location, industry, role type, or other criteria. You can also set notifications to receive alerts when new opportunities matching your preferences are posted.",
  },
];

export const EventsFAQ = [
  {
    question: "What is CONNECT and how does it works?",
    answer:
      "CONNECT is a platform designed to connect professionals in the Events sector. It allows users to network, find career opportunities, collaborate on projects, and promote events. You can create a profile, share your portfolio, and interact with other industry professionals.",
  },
  {
    question: "Who can join CONNECT?",
    answer:
      "The platform is open to all professionals in the Event sector, including, Vendors, Staffs, Decorators, Caterers, event planners, comedians, and more. Whether you are an experienced industry veteran or just starting your career, you are welcome to join.",
  },
  {
    question: "How do I find opportunities on CONNECT?",
    answer:
      "You can search for opportunities by exploring, gig listings, and job postings. Use filters to narrow down your search by location, industry, or other criteria. You can also set notifications to receive alerts when new opportunities matching your preferences are posted.",
  },
  {
    question: "How can I cancel my appointment?",
    answer:
      "As you check your booking status, there will be an option to cancel your appointment below it. Once you tap on a cancel appointment, it will be cancelled.",
  },
  {
    question:
      "How can I stay informed about new services and updates from CONNECT?",
    answer:
      "To stay informed about new services, updates, and promotions from Connect, you can follow us on social media, or regularly check our website for announcements.",
  },
];

export const HealthCareFAQ = [
  {
    question: "What sets your healthcare services apart from others?",
    answer:
      "At Connect, we stand out through our personalised care and a compassionate approach, delivered by our dedicated team of top healthcare professionals, ensuring the highest quality of service for our patients.",
  },
  {
    question: "How do I create a profile?",
    answer:
      "Simply go to our website, click on create account, and fill in the required information to get started.",
  },
  {
    question:
      "Can I schedule regular visits from a caregiver for homecare services?",
    answer:
      "Yes, you can schedule regular caregiver visits through our website. Simply select the timing that suits your needs, and our team will handle the rest.",
  },
  {
    question: "How can I cancel my appointment?",
    answer:
      "As you check your booking status, there will be an option to cancel your appointment below it. Once you tap on a cancel appointment, it will be canceled after entering the reason for cancellation.",
  },
  {
    question: "How can I reschedule my appointment?",
    answer:
      "As you check your booking status, there will be an option to reschedule your appointment below it. Once you tap on a reschedule appointment, it will be rescheduled after entering your desired time slot for appointment.",
  },
];

export const HomeServiceFAQ = [
  {
    question:
      "What should I do if I need to reschedule or cancel my appointment?",
    answer:
      "You can simplet call the customer support to reschedule and cancel the appointment.",
  },
  {
    question:
      "Is there a way to communicate directly with my assigned service provider?",
    answer:
      "Yes. As soon as the service provider is assigned to your order, you’ll be able to message over chat and via call.",
  },
  {
    question: "Do I need to provide any equipment or materials for the service?",
    answer:
      "Depends on the service you’d ordered but mostly all the equipment will be carried by the service provider as well.",
  },
  {
    question:
      "What happens if there is an issue or discrepancy with the service provided?",
    answer:
      "You can get in touch with the customer team via chat or our customer support number.",
  },
  {
    question: "What areas do you serve?",
    answer:
      "For now we are only operating in Mumbai, Hyderabad, Bengaluru and Pune.",
  },
];

export const PetCareFAQ = [
  {
    question: "What pet care services does CONNECT offer?",
    answer:
      "Connect currently offers a variety of pet care services, including pet grooming, dog walking, pet adoption facilitation, and pet breeding consultations.",
  },
  {
    question:
      "How does CONNECT ensure the quality and safety of its pet care services?",
    answer:
      "Connect thoroughly vets and screens all service providers to ensure they meet our standards for professionalism, expertise, and reliability. We also encourage community feedback and reviews to maintain transparency and accountability within our platform.",
  },
  {
    question:
      "How can I stay informed about new services and updates from CONNECT?",
    answer:
      "To stay informed about new services, updates, and promotions from Connect, you can follow us on social media, or regularly check our website for announcements.",
  },
  {
    question:
      "Is CONNECT available all over India, or is it limited to specific regions?",
    answer:
      "Currently, Connect operates in select regions, but we're continuously expanding our reach to serve pet owners and professionals all over India.",
  },
  {
    question:
      "How can pet service providers such as groomers, dog walkers, and trainers list their services on CONNECT's platform?",
    answer:
      "Pet service providers can easily list their services on Connect by creating an account and navigating to the relevant section. They can detail their services, experience, certifications, and expertise, and upload photos to attract clients. After reviewing and publishing their listing, they should monitor inquiries, respond promptly, and schedule appointments through the platform.",
  },
];
