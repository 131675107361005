import { useState, useMemo, useCallback, useEffect } from "react";
import styles from "./StepOne.module.css";
// import { FcGoogle } from "react-icons/fc";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { LiaSpinnerSolid } from "react-icons/lia";
import { IoCheckmarkSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import OtpInput from "../Otp/OtpInput";
import { Link } from "react-router-dom";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "../Documents/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../Documents/TermAndConditions/TermAndConditions";

const Signup = ({ setMobileNumber, setPassword }) => {
  const [show, setShow] = useState(null);
  const [secondsLeft, setSecondsleft] = useState(60);
  const [state, setState] = useState({
    isPassVis: false,
    verificationStage: 0,
    mobileNumber: "",
    password: "",
    isPasswordValid: false,
    isMobileNumberVerified: false,
    isTnCAccepted: -1,
    errors: {
      mobileNumber: "",
      otpFilled: false,
      otpVerified: false,
      password: "",
      rePassword: "",
    },
  });

  const togglePassVisibility = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isPassVis: !prevState.isPassVis,
    }));
  }, []);

  const onMobileChange = useCallback((e) => {
    const num = e.target.value;
    const mobileRegex = /^[789]\d{9}$/;
    setState((prevState) => ({
      ...prevState,
      mobileNumber: num,
      isMobileNumberVerified: mobileRegex.test(num),
    }));
    setMobileNumber(num);
  }, []);

  const onPasswordChange = useCallback((e) => {
    const pass = e.target.value;
    const passRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,20}$/;
    setState((prevState) => ({
      ...prevState,
      password: pass,
      isPasswordValid: passRegex.test(pass),
    }));
    setPassword(pass);
  }, []);

  const tncHandler = useCallback((e) => {
    setState((prevState) => ({
      ...prevState,
      isTnCAccepted: e.target.checked ? 1 : 0,
    }));
  }, []);

  const handleOtpFilled = useCallback((isFill) => {
    setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        otpFilled: isFill,
      },
    }));
  }, []);

  const handleOtpVerified = useCallback((isVerify) => {
    setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        otpVerified: isVerify,
      },
    }));
  }, []);

  const baseApiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const verificationHandler = async () => {
    if (
      state.verificationStage === 0 ||
      (secondsLeft === 60 && state.errors.otpVerified === false)
    ) {
      setState((prevState) => ({ ...prevState, verificationStage: 1 }));
      try {
        const res = await axios.post(
          `${baseApiUrl}/api/v1/auth/verification/generate`,
          {
            mobileNumber: state.mobileNumber,
            userType: "Service Provider",
            useCase: "Signup",
          },
          {
            withCredentials: true,
          }
        );
        console.log("Contact verification code sent :", res);
        alert(`Your verification code is :${res.data.data.code}`);
      } catch (error) {
        let count = 60;
        const timer = setInterval(() => {
          count = count - 1;
          setSecondsleft(count);
          if (count === 0) {
            setSecondsleft(60);
            clearInterval(timer);
          }
        }, 1000);
        toast.error(error.response.data.message);
        console.log("Error sending verification code :", error);
      }
    }
  };

  const canSignUp = useMemo(
    () =>
      state.isMobileNumberVerified &&
      state.isTnCAccepted === 1 &&
      state.errors.otpVerified &&
      state.isPasswordValid,
    [
      state.isMobileNumberVerified,
      state.isTnCAccepted,
      state.errors.otpVerified,
      state.isPasswordValid,
    ]
  );

  return (
    <div className={styles.cont}>
      <ToastContainer />
      <section className={styles.top}>
        <img
          className={styles.top__img}
          src={`/Assets/Authentication/logo.svg`}
          alt="logo"
        />
        <div className={styles.top__head}>Ready to join our community?</div>
      </section>
      <section className={styles.middle}>
        {/* <section className={styles.middle__continueWithGoogle}>
                    <FcGoogle className={styles.middle__continueWithGoogle__icon} />
                    <h3>Continue with Google</h3>
                </section> */}
        <section className={styles.middle__divider}>
          <section className={styles.middle__divider__line} />
          {/* <section className={styles.middle__divider__content}>or</section> */}
        </section>
        <section className={styles.middle__entries}>
          <div className={styles.middle__entries__field}>
            <div className={styles.middle__entries__field__head}>
              Mobile number
            </div>
            <div className={styles.middle__entries__field__inputVerify}>
              <input
                onChange={onMobileChange}
                disabled={state.verificationStage > 0}
                // className={styles.middle__entries__field__expand}
                className={
                  state.isMobileNumberVerified
                    ? styles.middle__entries__field__shrink
                    : styles.middle__entries__field__expand
                }
                type="number"
                placeholder="Enter your mobile number"
              />
              <span
                onClick={verificationHandler}
                className={
                  state.isMobileNumberVerified
                    ? styles.middle__entries__field__verifyButton__show
                    : styles.middle__entries__field__verifyButton__hide
                }
              >
                {state.verificationStage === 0 && "Verify"}
                {state.verificationStage === 1 && !state.errors.otpVerified && (
                  <LiaSpinnerSolid
                    className={
                      styles.middle__entries__field__verificationStage1Icon
                    }
                  />
                )}
                {state.errors.otpVerified && (
                  <IoCheckmarkSharp
                    className={
                      styles.middle__entries__field__verificationStage2Icon
                    }
                  />
                )}
              </span>
              {!state.isMobileNumberVerified &&
                state.mobileNumber.length !== 0 && (
                  <div className={styles.error}>Invalid mobile number!</div>
                )}
            </div>
          </div>
          {state.verificationStage !== 0 && (
            <div className={styles.middle__entries__field}>
              <div className={styles.middle__entries__field__otpHead}>
                <span>Enter the OTP</span>{" "}
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={verificationHandler}
                >
                  Resend OTP{" "}
                  <span
                    style={{
                      color: "grey",
                      display: secondsLeft === 60 ? "none" : "block",
                    }}
                  >
                    in 00:{secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}
                  </span>
                </span>
              </div>
              <div className={styles.middle__entries__field__otpCover}>
                <OtpInput
                  onOtpComplete={handleOtpFilled}
                  onOtpVerified={handleOtpVerified}
                  mobileNumber={state.mobileNumber}
                />
              </div>
              {!state.errors.otpFilled && (
                <div className={styles.error}>Please fill all OTP fields</div>
              )}
              {!state.errors.otpVerified && state.errors.otpFilled && (
                <div className={styles.error}>Invalid OTP</div>
              )}
            </div>
          )}
          <div className={styles.middle__entries__field}>
            <div className={styles.middle__entries__field__head}>
              Create your password
            </div>
            <div className={styles.middle__entries__field__cover}>
              <input
                className={styles.middle__entries__field__expand}
                type={state.isPassVis ? "text" : "password"}
                placeholder="Create your password"
                value={state.password}
                onChange={onPasswordChange}
              />
              {state.isPassVis ? (
                <LuEye
                  onClick={togglePassVisibility}
                  className={styles.middle__entries__field__eyeIcon}
                />
              ) : (
                <LuEyeOff
                  onClick={togglePassVisibility}
                  className={styles.middle__entries__field__eyeIcon}
                />
              )}
              {!state.isPasswordValid && state.password.length !== 0 && (
                <div className={styles.error}>
                  Your password must contain at least one uppercase letter, one
                  lowercase letter, one digit, one special character, and be
                  8-20 characters long!
                </div>
              )}
            </div>
          </div>
          <div className={styles.middle__entries__agree}>
            <input type="checkbox" onClick={tncHandler} />
            <label>
              {" "}
              I agree with the{" "}
              <Link
                to="/privacy/termAndCondition"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy/privacyPolicy"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </Link>
            </label>
            {!state.isTnCAccepted && (
              <div className={styles.error}>Need to be accepted!</div>
            )}
          </div>
        </section>
      </section>
      <section className={styles.bottom}>
        <Link to={"/auth/signup-step-2"}>
          <button
            disabled={!canSignUp}
            className={`${styles.bottomButton} ${
              !canSignUp ? styles.disabledBtn : ""
            }`}
          >
            Next
          </button>
        </Link>
        <div className={styles.bottom__login}>
          <div className={styles.bottom__login__left}>
            Already have an account?
          </div>
          <div className={styles.bottom__login__right}>
            <Link to={"/auth/login"}>Log in</Link>
          </div>
        </div>
      </section>
      {/* <PrivacyPolicy show={show} onClose={() => setShow(null)} />
      <TermsAndConditions show={show} onClose={() => setShow(null)} /> */}
    </div>
  );
};

export default Signup;
