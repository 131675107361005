export const AboutOpportunitiesEntertainment = [
  {
    image: "/Assets/Entertainment/Images/img8.webp",
    info: [
      {
        icon: "/Assets/Entertainment/Images/img9.webp",
        heading: "Audition Calls and Casting Opportunities",
        content:
          "Discover Roles: Browse curated audition calls and casting opportunities. Land Your Dream Role: Audition for film, TV, theatre, and commercials.",
      },
      {
        icon: "/Assets/Entertainment/Images/img10.webp",
        heading: "Gig Listings and Performance Opportunities",
        content:
          "Take the Stage: Explore live performance gigs in your area. Book Gigs: Apply for gigs matching your expertise and style.",
      },
      {
        icon: "/Assets/Entertainment/Images/img11.webp",
        heading: "Collaborative Projects and Creative Ventures",
        content:
          "Join Forces: Collaborate on films, music, theatre, and more. Expand Your Portfolio: Gain experience and connect with creatives.",
      },
    ],
  },
  {
    image: "/Assets/Entertainment/Images/img12.webp",
    info: [
      {
        icon: "/Assets/Entertainment/Images/img13.webp",
        heading: "Event Promotion and Production",
        content:
          "Put on a Show: Promote events and performances to targeted audiences. Sell Tickets: Use Connect’s tools to market and sell event tickets.",
      },
      {
        icon: "/Assets/Entertainment/Images/img14.webp",
        heading: "Industry Networking and Professional Development",
        content:
          "Build Your Network: Connect with industry professionals and mentors. Learn and Grow: Attend workshops and masterclasses by experts.",
      },
    ],
  },
  {
    image: "/Assets/Entertainment/Images/img15.webp",
    info: [
      {
        icon: "/Assets/Entertainment/Images/img16.webp",
        heading: "Showcase Your Talent and Portfolio",
        content:
          "Stand Out: Create profiles showcasing your talent and portfolio. Attract Opportunities: Capture attention from industry insiders.",
      },
      {
        icon: "/Assets/Entertainment/Images/img17.webp",
        heading: "Empower Your Dreams with Connect",
        content:
          "Join Connect: Leverage our platform to discover, connect, and succeed in entertainment.",
      },
    ],
  },
];

export const AboutOpportunitiesConstruction = [
  {
    image: "/Assets/Construction/Images/img13.webp",
    info: [
      {
        icon: "/Assets/Construction/Images/img14.webp",
        heading: "New Construction",
        content: "Help us create amazing new buildings from the ground up with your expertise.",
      },
      {
        icon: "/Assets/Construction/Images/img15.webp",
        heading: "Maintenance",
        content: "Keep existing buildings safe and functional by providing essential maintenance services.",
      },
    ],
  },
  {
    image: "/Assets/Construction/Images/img16.webp",
    info: [
      {
        icon: "/Assets/Construction/Images/img17.webp",
        heading: "Improvements",
        content: "Enhance and modernize spaces, adding new life and value to properties.",
      },
      {
        icon: "/Assets/Construction/Images/img18.webp",
        heading: "Collaborations",
        content: "Work with industry professionals to drive innovation and excellence in every project.",
      },
    ],
  },
];

export const AboutOpportunitiesEvent = [
  {
    image: "/Assets/Events/Images/img14.webp",
    info: [
      {
        icon: "/Assets/Events/Images/img15.webp",
        heading: "Wider Reach",
        content: "Expand your customer base by showcasing your services to a larger audience.",
      },
      {
        icon: "/Assets/Events/Images/img16.webp",
        heading: "Enhanced Visibility",
        content: "Increase your online presence with a dedicated profile on our app.",
      },
      {
        icon: "/Assets/Events/Images/img17.webp",
        heading: "Business Growth",
        content: "Boost your business through regular bookings and positive client reviews.",
      },
    ],
  },
  {
    image: "/Assets/Events/Images/img18.webp",
    info: [
      {
        icon: "/Assets/Events/Images/img19.webp",
        heading: "Networking Opportunities",
        content: "Connect with other professionals in the events industry and explore collaborations.",
      },
      {
        icon: "/Assets/Events/Images/img20.webp",
        heading: "Flexibility",
        content: "Choose your projects based on your availability and preferences.",
      },
    ],
  },
];

export const AboutOpportunitiesHealthCare = [
  {
    image: "/Assets/Healthcare/Images/img14.webp",
    info: [
      {
        icon: "/Assets/Healthcare/Images/img15.webp",
        heading: "Home Care",
        content: "Personalized care and assistance provided in the comfort of your home, ranging from basic household tasks to medical care.",
      },
      {
        icon: "/Assets/Healthcare/Images/img16.webp",
        heading: "Diagnostic Service",
        content: "Utilizing advanced medical technology to accurately diagnose diseases and conditions, aiding in effective treatment planning.",
      },
    ],
  },
  {
    image: "/Assets/Healthcare/Images/img17.webp",
    info: [
      {
        icon: "/Assets/Healthcare/Images/img18.webp",
        heading: "Telemedicine",
        content: "Providing remote medical consultations and services through digital communication technologies, enhancing access to healthcare and promoting convenience for patients.",
      },
      {
        icon: "/Assets/Healthcare/Images/img19.webp",
        heading: "Treatment and Therapy",
        content: "Comprehensive medical interventions and therapeutic approaches aimed at improving health and managing diseases.",
      },
    ],
  },
];

export const AboutOpportunitiesHomeService = [
  {
    image: "/Assets/HomeServices/Images/img20.webp",
    info: [
      {
        icon: "/Assets/HomeServices/Images/img21.webp",
        heading: "Diverse Categories",
        content: "Join a wide range of service categories",
      },
      {
        icon: "/Assets/HomeServices/Images/img22.webp",
        heading: "Flexible Work Hours",
        content: "Set your schedule; work full-time or part-time at your convenience.",
      },
      {
        icon: "/Assets/HomeServices/Images/img23.webp",
        heading: "Steady Client",
        content: "Access steady clients, build relationships, and grow your client base.",
      },
    ],
  },
  {
    image: "/Assets/HomeServices/Images/img24.webp",
    info: [
      {
        icon: "/Assets/HomeServices/Images/img25.webp",
        heading: "Professional Growth",
        content: "Enhance skills, gain feedback, and attract more business with diverse clients.",
      },
      {
        icon: "/Assets/HomeServices/Images/img26.webp",
        heading: "Reliable Earnings",
        content: "Enjoy secure and timely payments. Focus on your work without worrying about payment issues.",
      },
    ],
  },
];

export const AboutOpportunitiesPetCare = [
  {
    image: "/Assets/Petcare/Images/img14.webp",
    info: [
      {
        icon: "/Assets/Petcare/Images/img17.webp",
        heading: "Pet Care Services",
        content: "Explore our comprehensive range of pet care services, including grooming, training, Dog walking, pet adopting and pet breeding , tailored to meet the diverse needs of pets and their owners.",
      },
      {
        icon: "/Assets/Petcare/Images/img18.webp",
        heading: "Health and Welness",
        content: "Ensure your pet's optimal health and well-being with our range of preventive care options, promoting their overall happiness and vitality.",
      },
    ],
  },
  {
    image: "/Assets/Petcare/Images/img15.webp",
    info: [
      {
        icon: "/Assets/Petcare/Images/img19.webp",
        heading: "Training and Behavior",
        content: "Unlock your pet's full potential with our professional training and behavior modification services, fostering harmonious relationships between pets and their owners through positive reinforcement techniques.",
      },
      {
        icon: "/Assets/Petcare/Images/img20.webp",
        heading: "Community Engagement",
        content: "Join our inclusive pet care community and take part in collaborative efforts, such as partnerships with local organizations, community events, and educational opportunities, making a positive impact on pets and their owners.",
      },
    ],
  },
];
