import { ICONS } from "../../../Assets/Icons/index";
import styles from "./chatButton.module.css";

const ChatButton = () => {
  return (
    <div className={styles.chatButton}>
      <a
        href=" https://wa.me/message/XYVZSYLAI3XUB1"
        target="_blank"
        className={styles.topSocialmediaIcon}
      >
        {ICONS.chatLogo}
      </a>
    </div>
  );
};

export default ChatButton;
