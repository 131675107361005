import React, { useEffect, useRef, useState } from "react";

import { scroller } from "react-scroll";

import Styles from "./Petcare.module.css";
import CommonCss from "../../Styles/CommonStyle.module.css";
import Header from "../../../Sections/Header/Header";
import OurServiceCard from "../../../Components/OurServiceCard/OurServiceCard";
import WhyChooseCard from "../../../Components/WhyChooseCard/WhyChooseCard";
import FactDisplaySection from "../../../Components/FactDisplaySection/FactDisplaySection";
import PetCareLandingTop from "../../../Sections/LandingTop/petcare/PetcareLandingTop";
import Footer from "../../../Sections/Footer/Footer";
import GetStarted from "../../../Sections/GetStarted/GetStarted";
import AboutUs from "../../../Sections/AboutUs/AboutUs";
import OurProcess from "../../../Sections/OurProcess/OurProcess";
import { PetCareAboutUsData } from "../../../Sections/AboutUs/AboutUsData";
import { PetcareOurProcessData } from "../../../Sections/OurProcess/OurProcessData";
import FAQsList from "../../../Sections/FAQs/FAQsList";
import { PetCareFAQ } from "../../../Sections/FAQs/FAQsListData";
import AboutOpportunities from "../../../Sections/AboutOpportunities/AboutOpportunities";
import { AboutOpportunitiesPetCare } from "../../../Sections/AboutOpportunities/AboutOpportunitiesData";

const PetCareLanding = () => {
  const [activeSection, setActiveSection] = useState("section1");

  const sectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      const domNode = ref.current;
      // console.log(domNode, " ref.current;");
      if (domNode) {
        observer.observe(domNode);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        const domNode = ref.current;
        if (domNode) {
          observer.unobserve(domNode);
        }
      });
    };
  }, [sectionRefs]);

  const handleNavClick = (section) => {
    // console.log(section);
    scroller.scrollTo(section, {
      duration: 100,
      delay: 0,
      smooth: "linear",
      offset: -50,
    });
  };

  return (
    <>
      {/* --------------------------------------Header-----------------------------------------------   */}

      <Header
        handleNavClick={handleNavClick}
        activeSection={activeSection}
        primaryColor={"#CF76DD"}
        secondaryColor={"#fff"}
        backgroundColor={
          " linear-gradient(0deg, rgba(255, 181, 177, 0.50) 0%, rgba(255, 181, 177, 0.50) 100%), #F8F9FF"
        }
      />

      {/* -------------------------------------Landing Top-------------------------------------- */}

      <section id="section1" ref={sectionRefs.section1}>
        <PetCareLandingTop
          bgColor={
            "linear-gradient(180deg, rgba(255, 181, 177, 0.50) 0%, rgba(255, 181, 177, 0.00) 100%)"
          }
          tagColor={"#C970D7"}
          tagBgColor={"rgba(207, 118, 221, 0.10)"}
          tag={`#1 solution for petcare needs`}
          heading={`Join Our Network of Top-Tier Pet Care Professionals`}
          headingColor="#27001B"
          para={`We're excited to introduce you to our company and the wonderful
              world of pet care. Whether you're a pet owner looking for
              top-notch services or a pet lover interested in joining our
              community, you've come to the right place. Let's dive in and
              explore what we have to offer!`}
          image={`/Assets/heroImages/petcareHero.webp`}
          joinBtnC1={"#CF76DD"}
          joinBtnC2={"#F6E5F3"}
          bgImage={`url('/Assets/BackgroundImages/PetcareBackgroundImage.svg')`}
        />
      </section>

      {/* --------------------------------Fact display bar --------------------------------------- */}

      <section>
        <FactDisplaySection
          color={"#27001B"}
          backGroundColor={
            "linear-gradient(180deg, rgba(255, 181, 177, 0.50) 5%, rgba(205, 126, 217, 0.50) 100%"
          }
          otherColor={"#CF76DD"}
          lineColor={"#E2E2E2"}
          fact1={{ figure: 85, title: "Active Petcare Doctor" }}
          fact2={{ figure: 25, title: "Other service providers" }}
          fact3={{ figure: 900, title: "Active Users" }}
          fact4={{ figure: 50, title: "Active pet walkers" }}
        />
      </section>

      {/* ---------------------------------------About Us --------------------------------------------- */}

      <section
        className={CommonCss.section_top1}
        id="section2"
        ref={sectionRefs.section2}
      >
        <AboutUs data={PetCareAboutUsData} />
      </section>

      {/* -----------------------------------------Our Services------------------------------------------ */}

      <section
        className={Styles.section_top2}
        id="section3"
        ref={sectionRefs.section3}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Services
          </h2>
          <p className={CommonCss.section_subHeading}>
            Explore our comprehensive range of pet care services, including
            grooming, training, Dog walking, pet adopting and pet breeding ,
            tailored to meet the diverse needs of pets and their owners.
          </p>
          <div className={CommonCss.containerSection}>
            <OurServiceCard
              imageSource={"/Assets/Petcare/Images/img4.webp"}
              heading={"Pet Dating"}
              content={
                "Connect with reputable breeders for healthy and happy pets."
              }
            />

            <OurServiceCard
              imageSource={"/Assets/Petcare/Images/img5.webp"}
              heading={"Pet Adoption"}
              content={
                " Find your perfect pet companion through our seamless adoption services."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Petcare/Images/img6.webp"}
              heading={"Pet Training"}
              content={
                " Transform your pet's behavior with expert training tailored to their needs."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Petcare/Images/img7.webp"}
              heading={"Pet Walking"}
              content={
                "  Give your dog the exercise and attention they need with our dog walking services."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Petcare/Images/img8.webp"}
              heading={"Pet Grooming"}
              content={
                "Keep your pet looking and feeling their best with our top-notch grooming services."
              }
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------Why to choose us--------------------------------------------- */}

      <section
        className={CommonCss.section_top3}
        id="section4"
        ref={sectionRefs.section4}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Why Choose us
          </h2>
          <p className={CommonCss.section_subHeading}>
            Choose Connect for seamless pet care prioritizing your pet's
            well-being. With trusted professionals, innovative solutions, and
            personalized service, we ensure top-tier care tailored to your pet's
            unique needs.
          </p>
          <div className={CommonCss.containerSection}>
            <WhyChooseCard
              imageSource={"/Assets/Petcare/Images/img9.png"}
              width={"350px"}
              heading={"Passion for Pets"}
              content={
                " Dedicated pet lovers like you, our team knows the joy pets bring and ensures they receive the care they deserve"
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Petcare/Images/img10.png"}
              width={"350px"}
              heading={"Convenience"}
              content={
                " We value your time, so we make pet care easy. From grooming and walking to training and breeding, we've got you covered."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Petcare/Images/img11.png"}
              width={"350px"}
              heading={"Focus on Innovation"}
              content={
                "At Connect, we drive pet care innovation with new technology to enhance the lives of pets and their owners."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Petcare/Images/img12.png"}
              width={"350px"}
              heading={"Customer Satisfaction"}
              content={
                "Your satisfaction is our priority. We deliver exceptional service to ensure you and your pet are completely satisfied."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Petcare/Images/img13.png"}
              width={"350px"}
              heading={"Skilled Service Provider"}
              content={
                " Our platform connects pet owners with certified groomers, experienced vets, and trusted sitters, ensuring top-notch care."
              }
            />
          </div>
        </div>
      </section>

      {/* --------------------------------------About Opportunities -------------------------------------------- */}

      <section
        className={Styles.section_top4}
        id="section5"
        ref={sectionRefs.section5}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            About Opportunities
          </h2>
          <p className={CommonCss.section_subHeading}>
            Join our pet care community for exciting opportunities! Whether you
            enjoy grooming, training, breeding, or fostering, our platform has
            it all. Work with passionate individuals, make a difference in pets'
            lives, and start your fulfilling journey with us!
          </p>
          <AboutOpportunities data={AboutOpportunitiesPetCare} />
        </div>
      </section>

      {/* --------------------------------------Our Process -------------------------------------------- */}

      <section
        className={CommonCss.section_top7}
        id="section6"
        ref={sectionRefs.section6}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Process
          </h2>
          <p className={CommonCss.section_subHeading}>
            Explore our comprehensive range of pet care services, including
            grooming, training, dog walking, pet adoption, and pet breeding.
            Each service is tailored to meet the diverse needs of pets and their
            owners.
          </p>
          <OurProcess data={PetcareOurProcessData} />
        </div>
      </section>

      {/* --------------------------------------FAQ -------------------------------------------- */}

      <section
        className={Styles.section_top8}
        id="section7"
        ref={sectionRefs.section7}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            FAQs
          </h2>
          <p className={CommonCss.section_subHeading}>
            Answers to Common Inquiries About Our Pet Care Services.
          </p>
          <div className="text-center">
            <img
              src="/Assets/Petcare/Images/img21.webp"
              className={CommonCss.section_top8_img}
              alt="..."
            />
          </div>
          <FAQsList color="#fff" backgroundColor="#CF76DD" data={PetCareFAQ} />
        </div>
      </section>

      {/* --------------------------------------Footer and Get started -------------------------------------------- */}

      <section
        // className={Styles.section_top8}
        id="section8"
        ref={sectionRefs.section8}
      >
        <GetStarted
          heading={
            "Become a part of the Connect family and elevate your pet care business"
          }
          para={`Join us today to connect with fellow professionals and reach
                more pet owners seeking top-quality care. Together, we can set
                new standards in the pet care industry and ensure every pet
                receives the exceptional service they deserve.`}
          image={"/Assets/Petcare/Images/img16.webp"}
          smallImage={
            "/Assets/Petcare/Images/petcare_small_enquirenow_img.webp"
          }
          smallImageTop={"-38.5%"}
          bgColor={
            "linear-gradient(180deg, #FFB5B1 5%, #CD7ED9 100%), linear-gradient(90deg, #FFB5B1 0%, #CD7ED9 100%)"
          }
          buttonBgColor={"#CF76DD"}
          buttonColor={"white"}
        />
        <Footer
          contactColor="#CF76DD"
          iconsColor={"white"}
          iconsBgColor="#CF76DD"
          handleNavClick={handleNavClick}
        />
      </section>
    </>
  );
};

export default PetCareLanding;
