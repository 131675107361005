import React, { useEffect, useRef, useState } from "react";

import { scroller } from "react-scroll";
import "../../../utils/utilsCss/utilsCss.css";

import Styles from "./Construction.module.css";
import CommonCss from "../../Styles/CommonStyle.module.css";
import Modal from "../../../utils/modal/Modal";
import Enquiry from "./Enquiry";
import Header from "../../../Sections/Header/Header";
import OurServiceCard from "../../../Components/OurServiceCard/OurServiceCard";
import WhyChooseCard from "../../../Components/WhyChooseCard/WhyChooseCard";
import FactDisplaySection from "../../../Components/FactDisplaySection/FactDisplaySection";
import ConstructionLandingTop from "../../../Sections/LandingTop/construction/ConstructionLandingTop";
import Footer from "../../../Sections/Footer/Footer";
import GetStarted from "../../../Sections/GetStarted/GetStarted";
import AboutUs from "../../../Sections/AboutUs/AboutUs";
import OurProcess from "../../../Sections/OurProcess/OurProcess";
import AboutOpportunities from "../../../Sections/AboutOpportunities/AboutOpportunities";
import FAQsList from "../../../Sections/FAQs/FAQsList";
import { ConstructionFAQ } from "../../../Sections/FAQs/FAQsListData";
import { AboutOpportunitiesConstruction } from "../../../Sections/AboutOpportunities/AboutOpportunitiesData";
import { ConstructionOurProcessData } from "../../../Sections/OurProcess/OurProcessData";
import { ConstructionAboutUsData } from "../../../Sections/AboutUs/AboutUsData";

const ConstuctionLandig = () => {
  const [activeSection, setActiveSection] = useState("section1");

  const sectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      const domNode = ref.current;
      if (domNode) {
        observer.observe(domNode);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        const domNode = ref.current;
        if (domNode) {
          observer.unobserve(domNode);
        }
      });
    };
  }, [sectionRefs]);

  const handleNavClick = (section) => {
    scroller.scrollTo(section, {
      duration: 100,
      delay: 0,
      smooth: "linear",
      offset: -50,
    });
  };

  return (
    <>
      {/* --------------------------------------Header----------------------------------------------- */}

      <Header
        handleNavClick={handleNavClick}
        activeSection={activeSection}
        backgroundColor={
          "linear-gradient(0deg, rgba(22, 93, 201, 0.30) 0%, rgba(22, 93, 201, 0.30) 100%), #F8F9FF"
        }
        primaryColor={"linear-gradient(0deg, #FED462 0%, #FED462 100%), #000"}
      />

      {/* -------------------------------------Landing Top-------------------------------------- */}

      <section id="section1" ref={sectionRefs.section1}>
        <ConstructionLandingTop
          bgColor={"#F8F9FF"}
          heading={"Build Your Vision with Expert Construction Services"}
          headingColor="#00183D"
          para={`From project management to procurement, we provide comprehensive
                        construction solutions. Our skilled professionals ensure quality
                        and efficiency at every stage. Let's construct something
                        extraordinary together.`}
          tag={`#1 solution for construction needs`}
          tagColor={"#165DC9"}
          tagBgColor={"rgba(22, 93, 201, 0.10)"}
          image={`/Assets/heroImages/constructionHero.webp`}
          joinBtnC1={"#FED462"}
          joinBtnC2={"rgba(254, 212, 98, 0.10)"}
          bgImage={`url('/Assets/BackgroundImages/ConstructionBackgroundImage.svg')`}
        />
      </section>

      {/* --------------------------------Fact display bar --------------------------------------------- */}

      <section>
        <FactDisplaySection
          color={"#fff"}
          backGroundColor={"#165DC9"}
          otherColor={"#FED462"}
          lineColor={"#FCFCFC"}
          fact1={{ figure: 200, title: "Active Labourers" }}
          fact2={{ figure: 100, title: "Other service providers" }}
          fact3={{ figure: 1000, title: "Active User" }}
          fact4={{ figure: 50, title: "Active Engineers" }}
        />
      </section>

      {/* --------------------------------------About us ---------------------------------------- */}

      <section
        className={CommonCss.section_top1}
        id="section2"
        ref={sectionRefs.section2}
      >
        <AboutUs data={ConstructionAboutUsData} />
      </section>

      {/* -----------------------------------------Our Services------------------------------------------ */}

      <section
        className={Styles.section_top2}
        id="section3"
        ref={sectionRefs.section3}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Services
          </h2>
          <p className={CommonCss.section_subHeading}>
            We offer skilled labor, expert contractors, innovative engineers,
            creative interior designers, and visionary architects. From new
            builds to renovations, our comprehensive construction services
            ensure high-quality results.
          </p>
          <div className={CommonCss.containerSection}>
            <OurServiceCard
              imageSource={"/Assets/Construction/Images/img4.webp"}
              heading={"Labors"}
              content={
                " Skilled laborers ensure precise, high-quality workmanship for all projects. They complete tasks on time and within budget, guaranteeing strong, appealing builds."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Construction/Images/img5.webp"}
              heading={"Contractors"}
              content={
                " Experienced contractors oversee the entire process, ensuring seamless integration and adherence to timelines. They efficiently manage resources to turn your vision into reality."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Construction/Images/img6.webp"}
              heading={"Engineers"}
              content={
                "  Engineers provide technical expertise and innovative solutions, ensuring designs are safe and sustainable. The drive the success of complex projects with their skills."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Construction/Images/img7.webp"}
              heading={"Interior Designers"}
              content={
                " Interior designers create functional, beautiful environments tailor client preferences. They transform spaces with creativity and elegance."
              }
            />

            <OurServiceCard
              imageSource={"/Assets/Construction/Images/img8.webp"}
              heading={"Architects"}
              content={
                "Architects blend artistry with technical skill to design innovative, sustainable structures. They ensure projects meet practical needs and inspire delight."
              }
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------Why to choose us--------------------------------------------- */}

      <section
        className={CommonCss.section_top3}
        id="section4"
        ref={sectionRefs.section4}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Why Choose us
          </h2>

          <p className={CommonCss.section_subHeading}>
            Choose Connect for a super app experience in construction. With
            years of experience, we deliver on time and customise our work to
            fit your needs.
          </p>
          <div className={CommonCss.containerSection}>
            <WhyChooseCard
              imageSource={"/Assets/Construction/Images/img9.png"}
              heading={"Focus On Innovation"}
              content={
                "  We focus on innovation and making sure you're happy. "
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Construction/Images/img10.png"}
              heading={"Skilled Labourers"}
              content={
                " Our diverse team of skilled labourers ensures every detail is perfect. "
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Construction/Images/img11.png"}
              heading={"Excellence"}
              content={"Pick us for excellence"}
            />
            <WhyChooseCard
              imageSource={"/Assets/Construction/Images/img12.png"}
              heading={"Vision"}
              content={" Your vision will soar with our help. "}
            />
          </div>
        </div>
      </section>

      {/* ----------------------------------------------about opportunity---------------------------------------------------- */}

      <section
        className={Styles.section_top4}
        id="section5"
        ref={sectionRefs.section5}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            About Opportunities
          </h2>
          <p className={CommonCss.section_subHeading}>
            Discover limitless growth within the construction realm with our
            pioneering super app. Seamlessly connect with a diverse network of
            labourers, contractors, and suppliers.Join us in revolutionising the
            construction industry, one tap at a time.
          </p>
          <AboutOpportunities data={AboutOpportunitiesConstruction} />
        </div>
      </section>

      {/* //----------------------------------OUR PROCESS------------------------------------------ */}

      <section
        className={CommonCss.section_top7}
        id="section6"
        ref={sectionRefs.section6}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Process
          </h2>
          <p className={CommonCss.section_subHeading}>
            Our streamlined process ensures high standards from initial
            consultation to project completion. We prioritize your satisfaction
            through careful planning, expert execution, and thorough
            post-service support.
          </p>
          <OurProcess data={ConstructionOurProcessData} />
        </div>
      </section>

      {/*  -----------------------------------FAQ-------------------------------------------------- */}

      <section
        className={Styles.section_top8}
        id="section7"
        ref={sectionRefs.section7}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            FAQs
          </h2>
          <p className={CommonCss.section_subHeading}>
            Find answers to common questions about our construction services.
          </p>
          <div className="text-center">
            <img
              src="/Assets/Construction/Images/img19.webp"
              className={CommonCss.section_top8_img}
              alt="..."
            />
          </div>
          <FAQsList
            color="black"
            backgroundColor="#FED462"
            data={ConstructionFAQ}
          />
        </div>
      </section>

      {/*  -----------------------------------Footer and Get started----------------------------------------- */}

      <section
        // className={Styles.section_top8}
        id="section8"
        ref={sectionRefs.section8}
      >
        <GetStarted
          heading={"Ready to Bring Your Project to Life?"}
          para={`Get started with our expert construction services today. Whether
                it's a small renovation or a large-scale project, we’re here to
                make your vision a reality. Click "Get Started" to begin your
                journey with us.`}
          image={"/Assets/Construction/Images/img20.webp"}
          smallImage={
            "/Assets/Construction/Images/construction_enqireNow_small_img.svg"
          }
          bgColor={"#165DC9"}
          buttonBgColor={"#FED462"}
          buttonColor={"black"}
        />
        <Footer
          contactColor="#FED462"
          iconsBgColor="#FED462"
          handleNavClick={handleNavClick}
        />
      </section>
    </>
  );
};

export default ConstuctionLandig;
