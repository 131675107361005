import { useEffect, useState } from 'react';
import Styles from './FactDisplaySection.module.css';

const FactDisplaySection = ({ color, backGroundColor, otherColor, lineColor, fact1, fact2, fact3, fact4 }) => {

  const [statCount, setStatCount] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0
  });

  const finalValues = {
    one: fact1.figure,
    two: fact2.figure,
    three: fact3.figure,
    four: fact4.figure,
  };

  useEffect(() => {
    let totalIncrements = 0;
    const incrementValue = 1;
    let intervalTime = 5;

    const incrementValues = () => {
      setStatCount((prev) => {
        const newCounts = { ...prev };
        Object.keys(finalValues).forEach((key) => {
          if (newCounts[key] < finalValues[key]) {
            newCounts[key] += incrementValue;
          }
          if (newCounts[key] >= finalValues[key]) {
            newCounts[key] = finalValues[key];
          }
        });
        totalIncrements += incrementValue * Object.keys(finalValues).length;

        if (totalIncrements >= 100) {
          intervalTime = intervalTime / 1000;
        }

        return newCounts;
      });
    };

    const interval = setInterval(incrementValues, intervalTime);

    return () => clearInterval(interval);
  }, [statCount]);

  const formatNumber = (number) => {
    if (number >= 1000) {
      const formatted = (number / 1000).toFixed(1);
      return formatted.endsWith('.0') ? formatted.slice(0, -2) + 'K' : formatted + 'K';
    }
    return number;
  };

  return (
    <div style={{ color: color, background: backGroundColor }} className={` ${Styles.cont}`}>
      <div className={Styles.cont_inside}>

        <div className={Styles.data_section} style={{ borderRight: `1px solid ${lineColor}` }}>
          <section className={Styles.data_section_top}>
            <h2>
              {formatNumber(statCount.one)}<span style={{ color: otherColor }}>+</span>
            </h2>
          </section>
          <section className={Styles.data_section_bottom}>
            <p>{fact1.title}</p>
          </section>
        </div>
        <div className={Styles.data_section} style={{ borderRight: `1px solid ${lineColor}` }}>
          <section className={Styles.data_section_top}>
            <h2>
              {formatNumber(statCount.two)}<span style={{ color: otherColor }}>+</span>
            </h2>
          </section>
          <section className={Styles.data_section_bottom}>

            <p>{fact2.title}</p>
          </section>

        </div>
        <div className={Styles.data_section} style={{ borderRight: `1px solid ${lineColor}` }}>
          <section className={Styles.data_section_top}>

            <h2>
              {formatNumber(statCount.three)}<span style={{ color: otherColor }}>+</span>
            </h2>
          </section>
          <section className={Styles.data_section_bottom}>

            <p>{fact3.title}</p>
          </section>
        </div>
        <div className={Styles.data_section} >
          <section className={Styles.data_section_top}>

            <h2>
              {formatNumber(statCount.four)}<span style={{ color: otherColor }}>+</span>
            </h2>
          </section>
          <section className={Styles.data_section_bottom}>

            <p>{fact4.title}</p>
          </section>

        </div>
      </div>
    </div>
  );
};

export default FactDisplaySection;
