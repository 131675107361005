import Styles from "./OurProcess.module.css";

const OurProcess = ({ data }) => {

  return (
  
      <div className={Styles.section_ourProcess_cont}>
        {data?.map((process, index) => (
          <section
            className={
              index % 2 !== 0
                ? `${Styles.ourProcess_section} ${Styles.even}`
                : Styles.ourProcess_section
            }
          >
            <section
              className={
                index % 2 === 0
                  ? Styles.ourProcess_section_left
                  : `${Styles.ourProcess_section_left} ${Styles.even_number}`
              }
            >
              <span
                className={Styles.ourProcess_section_left_inside}
                style={{ height: process.shortContent ? "2rem" : "7rem" }}
              >
                0{index + 1}
              </span>
            </section>

            <section className={Styles.ourProcess_section_middle}>
              <section className={Styles.ourProcess_section_middle_top}>
                <img
                  src={process.image}
                  className={Styles.ourProcess_section_middle_top_inside}
                />
              </section>
              <section
                style={{
                  visibility:
                    index === data.length - 1 ? "hidden" : "visible",
                }}
                className={Styles.ourProcess_section_middle_bottom}
              >
                <svg height="100%" width={10}>
                  <line
                    x1={5}
                    y1={0}
                    x2={5}
                    y2={200}
                    style={{
                      stroke: "rgba(0, 0, 0, 0.5)",
                      strokeWidth: "1",
                      strokeDasharray: "7, 5",
                    }}
                  />
                </svg>
              </section>
            </section>

            <section className={Styles.ourProcess_section_right}>
              <section
                className={Styles.ourProcess_section_right_content}
                style={{ backgroundColor: process.backgroundColor }}
              >
                <img
                  src={process.polygon}
                  className={
                    index % 2 === 0
                      ? Styles.ourProcess_section_triangle
                      : `${Styles.ourProcess_section_triangle_even}`
                  }
                />
                <h5 className={Styles.heading} style={{ color: process.color }}>{process.info.heading}</h5>
                <p className={Styles.content} >{process.info.content}</p>
              </section>
            </section>
          </section>
        ))}
      </div>
   
  );
};

export default OurProcess;
