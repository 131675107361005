import { ICONS } from "../../../Assets/Icons/index";
import styles from "./MoreDetailButton.module.css";
import { Link } from "react-router-dom";

const MoreDetailBtn = ({ text, backgroundcolor, color, to = "/" }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link
      to={to}
      style={{ textDecoration: "none", width: "100%" }}
      onClick={scrollToTop}
    >
      <div
        style={{
          background: backgroundcolor,
          color: color,
        }}
        className={styles.moreDetailBtn}
      >
        {text}{" "}
        <span className={styles.moreDetailIcon}>
          {" "}
          {color === "#FFF" ? ICONS.arrowKeyWhite : ICONS.arrowKeyBlack}
        </span>
      </div>
    </Link>
  );
};

export default MoreDetailBtn;
