import React, { useEffect, useRef, useState } from "react";

import { scroller } from "react-scroll";

import Styles from "./Event.module.css";
import CommonCss from "../../Styles/CommonStyle.module.css";
import Header from "../../../Sections/Header/Header";
import WhyChooseCard from "../../../Components/WhyChooseCard/WhyChooseCard";
import OurServiceCard from "../../../Components/OurServiceCard/OurServiceCard";
import FactDisplaySection from "../../../Components/FactDisplaySection/FactDisplaySection";
import EventLandingTop from "../../../Sections/LandingTop/event/EventLandingTop";
import Footer from "../../../Sections/Footer/Footer";
import GetStarted from "../../../Sections/GetStarted/GetStarted";
import AboutUs from "../../../Sections/AboutUs/AboutUs";
import OurProcess from "../../../Sections/OurProcess/OurProcess";
import FAQsList from "../../../Sections/FAQs/FAQsList";
import { EventsFAQ } from "../../../Sections/FAQs/FAQsListData";
import AboutOpportunities from "../../../Sections/AboutOpportunities/AboutOpportunities";
import { AboutOpportunitiesEvent } from "../../../Sections/AboutOpportunities/AboutOpportunitiesData";
import { EventOurProcessData } from "../../../Sections/OurProcess/OurProcessData";
import { EventAboutUsData } from "../../../Sections/AboutUs/AboutUsData";

const EventsLanding = () => {
  const [activeSection, setActiveSection] = useState("section1");

  const sectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      const domNode = ref.current;
      // console.log(domNode, " ref.current;");
      if (domNode) {
        observer.observe(domNode);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        const domNode = ref.current;
        if (domNode) {
          observer.unobserve(domNode);
        }
      });
    };
  }, [sectionRefs]);

  const handleNavClick = (section) => {
    scroller.scrollTo(section, {
      duration: 100,
      delay: 0,
      smooth: "linear",
      offset: -50,
    });
  };

  return (
    <>
      {/* --------------------------------------Header----------------------------------------------- */}

      <Header
        handleNavClick={handleNavClick}
        activeSection={activeSection}
        primaryColor={
          "linear-gradient(0deg, #FF8A00 0%, #FF8A00 100%), #281508"
        }
        secondaryColor={"#fff"}
        backgroundColor={"#FBEFC6"}
      />

      {/* -------------------------------------Landing Top-------------------------------------- */}

      <section id="section1" ref={sectionRefs.section1}>
        <EventLandingTop
          bgColor={
            "linear-gradient(180deg, #FBEFC6 0%, rgba(251, 239, 198, 0.20) 114.4%)"
          }
          heading={`Find the Best Teams and Vendors for Your Event`}
          headingColor="#3E1B03"
          para={` From project management to procurement, we provide comprehensive
              construction solutions. Our skilled professionals ensure quality
              and efficiency at every stage. Let's construct something
              extraordinary together.`}
          joinBtnC1={"#FF8A00"}
          joinBtnC2={"#FBE6B3"}
          image={"/Assets/heroImages/eventHero.webp"}
          bgImage={`url('/Assets/BackgroundImages/EventBackgroundImage.svg')`}
        />
      </section>

      {/* --------------------------------Fact display bar --------------------------------------------- */}

      <section>
        <FactDisplaySection
          color={"#3E1B03"}
          backGroundColor={"#FBEFC6"}
          otherColor={"#FF8A00"}
          lineColor={"#E2E2E2"}
          fact1={{ figure: 20, title: "Event Organizers" }}
          fact2={{ figure: 40, title: "Other service providers" }}
          fact3={{ figure: 60, title: "Active Workers" }}
          fact4={{ figure: 15, title: "Event Hostess" }}
        />
      </section>

      {/* ----------------------------------About Us --------------------------------------------- */}

      <section
        className={CommonCss.section_top1}
        id="section2"
        ref={sectionRefs.section2}
      >
        <AboutUs data={EventAboutUsData} />
      </section>

      {/* -----------------------------------------Our Services------------------------------------ */}

      <section
        className={Styles.section_top2}
        id="section3"
        ref={sectionRefs.section3}
      >
        <div className="container">
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Services
          </h2>
          <p className={CommonCss.section_subHeading}>
            At CONNECT, we are dedicated to making your daily life easier and
            more enjoyable by providing a wide range of high-quality services
            tailored to your needs. Here’s what we offer:
          </p>
          <div className={CommonCss.containerSection}>
            <OurServiceCard
              imageSource={"/Assets/Events/Images/img4.webp"}
              heading={"Artists"}
              textAlign={"left"}
              content={
                "Musicians: Bands, solo artists, DJs, and instrumentalists."
              }
              content2={"Performers: Dancers, magicians, comedians, and more."}
              content3={
                "Visual Artists: Painters, caricature artists, and live art performers."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Events/Images/img5.webp"}
              heading={"Anchors"}
              content="Event Hosts: Professional emcees and speakers for all types of events."
              content2={
                "Masters of Ceremonies: Experienced MCs to guide your event smoothly."
              }
              content3={
                "Entertainers: Charismatic hosts to engage and entertain your guests."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Events/Images/img6.webp"}
              heading={"Decorators"}
              content=" Venue Decoration: Transform your event space with stunning decor."
              content2={
                "Theme Planning: Creative and unique themes tailored to your event."
              }
              content3={
                " Floral Arrangements: Beautiful and bespoke floral designs for any occasion."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Events/Images/img7.webp"}
              heading={"Caterers"}
              content={
                " Catering Services:  From gourmet meals to finger foods and buffets."
              }
              content2={
                "Custom Menus: Tailored culinary experiences to suit your tastes and preferences."
              }
              content3={
                " Special Dietary Needs:  Options for vegan, gluten-free, and other dietary requirements."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Events/Images/img8.webp"}
              heading={"Other Vendors"}
              content="Photographers: Capture every moment with professional photography."
              content2={
                "Videographers: High-quality video services to document your event."
              }
              content3={
                " Sound and Lighting: Ensure perfect acoustics and lighting for your venue. "
              }
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------Why to choose us--------------------------------------------- */}

      <section
        className={CommonCss.section_top3}
        id="section4"
        ref={sectionRefs.section4}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Why Choose us
          </h2>
          <p className={CommonCss.section_subHeading}>
            Discover the ultimate solution for all your event needs with our
            super app! We provide a seamless platform for organizers, customers,
            businesses, vendors, and labor to connect and collaborate. Choose us
            for:
          </p>
          <div className={CommonCss.containerSection}>
            <WhyChooseCard
              imageSource={"/Assets/Events/Images/img9.png"}
              heading={"Comprehensive Services"}
              content={
                "Hire vendors, hire teams, and manage all event logistics in one place."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Events/Images/img10.png"}
              heading={"User-Friendly Interface"}
              content={
                "Our app is designed for ease of use, ensuring a smooth experience for all users."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Events/Images/img11.png"}
              heading={"Enhanced Visibility"}
              content={
                " Gain exposure and connect with more clients and service providers."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Events/Images/img12.png"}
              heading={"Efficient Communication"}
              content={
                "Streamline your event planning process with our integrated communication tools."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Events/Images/img13.png"}
              heading={"24/7 Support"}
              content={
                "Our dedicated support team is always available to assist you."
              }
            />
          </div>
        </div>
      </section>

      {/* ------------------------------------about opportunities----------------------------------- */}

      <section
        className={Styles.section_top4}
        id="section5"
        ref={sectionRefs.section5}
      >
        <div className="container">
          <div className="row">
            <h2
              className={`${CommonCss.heading} ${Styles.section_heading_color} `}
            >
              About Opportunities
            </h2>
            <p className={CommonCss.section_subHeading}>
              Join our growing platform and unlock new opportunities for your
              business
            </p>
            <AboutOpportunities data={AboutOpportunitiesEvent} />
          </div>
        </div>
      </section>

      {/* -------------------------------------------Our Process-------------------------------------- */}

      <section
        className={CommonCss.section_top7}
        id="section6"
        ref={sectionRefs.section6}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Process
          </h2>
          <p className={CommonCss.section_subHeading}>
            Our streamlined process ensures a hassle-free experience for all
            users
          </p>
          <OurProcess data={EventOurProcessData} />
        </div>
      </section>

      {/* --------------------------------------FAQ--------------------------------------------- */}

      <section
        className={Styles.section_top8}
        id="section7"
        ref={sectionRefs.section7}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            FAQs
          </h2>
          <p className={CommonCss.section_subHeading}>
            Answers to Common Inquiries About Entertainment Services.
          </p>
          <div className="text-center">
            <img
              src="/Assets/Events/Images/img21.webp"
              className={CommonCss.section_top8_img}
              alt="..."
            />
          </div>
          <FAQsList color="#fff" backgroundColor="#FF6B00" data={EventsFAQ} />
        </div>
      </section>

      {/* ----------------------------------------Footer and get started-------------------------------------- */}

      <section
        // className={Styles.section_top8}
        id="section8"
        ref={sectionRefs.section8}
      >
        <GetStarted
          heading={"Elevate Your Event with the Right Professionals"}
          para={"Join us today and make your event unforgettable!"}
          image={"/Assets/Events/Images/events_enquireNow_image.svg"}
          smallImage={"/Assets/Events/Images/events_small_enquirenow_img.webp"}
          smallImageTop="-23%"
          smallImageMarginBottom="5%"
          bgColor={"#FF8A00"}
          buttonBgColor={"#FF8A00"}
          buttonColor={"white"}
        />
        <Footer
          contactColor="#FF8A00"
          iconsColor={"white"}
          iconsBgColor="#FF8A00"
          handleNavClick={handleNavClick}
        />
      </section>
    </>
  );
};

export default EventsLanding;
