import styles from "./Intro.module.css";
import styles_servicecard from "../../pages/Intro/Components/SectorCard/SectorCard.module.css";
import styles_getStarted from "../../../Sections/GetStarted/GetStarted.module.css";

import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import React, { useState, useEffect, useRef } from "react";

import Navbar from "../Intro/Components/Navbar/Navbar";
import MoreDetailBtn from "../../components/moreDetailButton/MoreDetailButton";
import ChatButton from "../../components/chatButton/chatButton";
import TopSection from "./Components/TopSection/TopSection";
import Footer from "../../../Sections/Footer/Footer";
import SectorCard from "./Components/SectorCard/SectorCard";


const Intro = () => {
  const [sticky, setSticky] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("section1");
  const [firstRender, setFirstRender] = useState(true);
  //console.log(activeSection);

  // <--------------------- activation of navbar option on scroll event ------------------------------>

  const sectionRefs = {
    section1: useRef(null),
    navbar: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
    section10: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.75,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      const domNode = ReactDOM.findDOMNode(ref.current);
      if (domNode) {
        observer.observe(domNode);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        const domNode = ReactDOM.findDOMNode(ref.current);
        if (domNode) {
          observer.unobserve(domNode);
        }
      });
    };
  }, [sectionRefs]);

  // <----------------------- Scroll from section to section ------------------------------------>

  const sections = [
    "section1",
    "section3",
    "section4",
    "section5",
    "section6",
    "section7",
    "section8",
    "section9",
    "section10",
  ];

  let currentSectionIndex = 0;

  const scrollToSection = (index) => {
    if (index >= 0 && index < sections.length) {
      scroller.scrollTo(sections[index], {
        duration: 50,
        smooth: true,
      });
      currentSectionIndex = index;
    }
  };

  const updateCurrentSectionIndex = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2; // Middle of the viewport
    for (let i = 0; i < sections.length; i++) {
      const section = document.getElementById(sections[i]);
      if (
        section &&
        section.offsetTop <= scrollPosition &&
        section.offsetTop + section.offsetHeight > scrollPosition
      ) {
        currentSectionIndex = i;
        break;
      }
    }
  };

  const handleScroll = (event) => {
    event.preventDefault();

    if (!firstRender) {
      updateCurrentSectionIndex();
      const direction = event.deltaY > 0 ? 1 : -1;
      const nextSectionIndex = currentSectionIndex + direction;
      scrollToSection(nextSectionIndex);
    } else {
      setFirstRender(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    window.addEventListener("wheel", handleScroll, { passive: false });
    window.addEventListener("scroll", updateCurrentSectionIndex);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("scroll", updateCurrentSectionIndex);
    };
  }, [firstRender]);

  // <---------------------srcoll handling for navbar ------------------------------>

  const handleSpecialSectionScroll = () => {
    if (window.scrollY >= window.innerHeight) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const handleNavbarScroll = () => {
    if (window.scrollY === 0) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    window.addEventListener("scroll", handleSpecialSectionScroll);
    window.addEventListener("scroll", handleNavbarScroll);
    return () => {
      window.removeEventListener("scroll", handleSpecialSectionScroll);
      window.removeEventListener("scroll", handleNavbarScroll);
    };
  }, []);

  // <--------------------- scroll on clicking get started button ------------------------------>

  const getStartedBtn = () => {
    const sections = [
      "section1",
      // "section2",
      "section3",
      "section4",
      "section5",
      "section6",
      "section7",
      "section8",
      "section9",
    ];
    scroller.scrollTo(sections[1], {
      duration: 100,
      delay: 0,
      smooth: "linear",
    });
  };

  // <--------------------- scroll on clicking navbar option button ------------------------------>

  const handleNavClick = (section) => {
    scroller.scrollTo(section, {
      duration: 100,
      delay: 0,
      smooth: "linear",
    });
  };

  return (
    <div className={styles.introPage}>
      <ChatButton />

      <Element
        name="section1"
        className={`${styles.section} ${styles.topElement}`}
        id="section1"
        ref={sectionRefs.section1}
      >
        <TopSection getStartedBtn={getStartedBtn} />
      </Element>

      <Element
        name="section2"
        id="navbar"
        ref={sectionRefs.navbar}
        className={` ${styles.navbarContainer} ${sticky ? styles.sticky : ""}`}
      >
        <Navbar
          sticky={sticky}
          isScrolled={isScrolled}
          activeSection={activeSection}
          handleNavClick={handleNavClick}
          getStartedBtn={getStartedBtn}
        />
      </Element>

      <Element
        name="section3"
        className={styles.section}
        id="section3"
        ref={sectionRefs.section3}
      >
        <SectorCard
          id="1"
          title="Construction"
          tagline=" Build Your Vision with Expert Construction Services"
          content="From project management to procurement, we provide comprehensive construction solutions. Our skilled professionals ensure quality and efficiency at every stage. Let's construct something extraordinary together."
          image="/Assets/MainPage/IntroPageImg/Frame construction.webp"
          hoverClass={"containerContent1"}
          moreDetail={{
            backgroundcolor: "rgba(254, 212, 98, 1)",
            color: "rgba(0, 0, 0, 1)",
          }}
        />
      </Element>
      <Element
        name="section4"
        className={styles.section}
        id="section4"
        ref={sectionRefs.section4}
      >
        <div className={styles_servicecard.container}>
          <div className={styles_servicecard.childContainer}>
            <div className={styles_servicecard.headingShadow}>Health Care</div>
            <div
              className={`${styles_servicecard.containerContent} ${styles_servicecard.containerContent2}`}
            >
              <div className={styles_servicecard.contentText}>
                <div>
                  <p
                    className={`${styles_servicecard.contentTextHeader} ${styles.healthcare_Header}`}
                  >
                    Your Health,
                  </p>
                  <p className={styles_servicecard.contentTextHeader}>
                    Our Promise of Excellence and Care
                  </p>
                </div>
                <p className={styles_servicecard.contentTextDetail}>
                  Connect offers personalized healthcare solutions, from home
                  care to telemedicine, ensuring top-notch care with innovation
                  and compassion. Trust us to enhance your health and quality of
                  life.
                </p>
                <div className={styles_servicecard.contentTextFooter}>
                  <MoreDetailBtn
                    text="More Details"
                    backgroundcolor="rgba(255, 132, 124, 1)"
                    color="#FFF"
                    to="/healthcare"
                  />
                  {/* <LoginButton backgroundColor="rgba(255, 132, 124, 0.10)" /> */}
                  <button
                    className={styles_servicecard.partner}
                    style={{
                      backgroundColor: "rgba(254, 212, 98, 0.10)",
                      border: `1px solid rgba(255, 132, 124, 1)`,
                    }}
                  >
                    <Link
                      className={styles_servicecard.link}
                      // to={
                      //   "https://docs.google.com/forms/d/e/1FAIpQLSfO8cGrFumb2ZYWu7rnvRDLGpgHYAaWiSrd06PDdBE3vmTVEg/viewform"
                      // }
                      to={`/auth/signup-step-1`}
                    // target="_blank"
                    >
                      <i className="fa-solid fa-handshake"></i>&nbsp; Partner
                      with us
                    </Link>
                  </button>
                </div>
              </div>
              <img
                src="/Assets/MainPage/IntroPageImg/Frame healthcare.webp"
                alt=""
                className={styles_servicecard.contentImageRight}
              />
            </div>
          </div>
        </div>
      </Element>
      <Element
        name="section5"
        className={styles.section}
        id="section5"
        ref={sectionRefs.section5}
      >
        <SectorCard
          id="3"
          title="Event"
          tagline="  Find the Best Teams and Vendors for Your Event"
          content="   Discover and hire top-rated teams and vendors effortlessly.
                  Whether you need caterers, entertainers, decorators, or
                  support staff, our platform connects you with the best
                  professionals in the industry."
          image="/Assets/MainPage/IntroPageImg/Frame event.webp"
          hoverClass={"containerContent3"}
          moreDetail={{
            backgroundcolor: "#FF8A00",
            color: "#FFF",
          }}
        />
      </Element>
      <Element
        name="section6"
        className={styles.section}
        id="section6"
        ref={sectionRefs.section6}
      >
        <SectorCard
          id="4"
          title="Entertainment"
          tagline="  Your Gateway to the Entertainment World"
          content=" Join our vibrant community of entertainment professionals and
                  enthusiasts. Network, collaborate, and celebrate the arts in
                  music, film, theatre, and more."
          image="/Assets/MainPage/IntroPageImg/frame entertainment.webp"
          hoverClass={"containerContent4"}
          moreDetail={{
            backgroundcolor: "#FF6B00",
            color: "#FFF",
          }}
        />
      </Element>
      <Element
        name="section7"
        className={styles.section}
        id="section7"
        ref={sectionRefs.section7}
      >
        <SectorCard
          id="5"
          title="Home Services"
          tagline="Experience Top-Notch Home Services with CONNECT"
          content='Professinal, Reliable, and Convenient Solutions Tailored to Your Needs-All at Your Doorstep'
          image="/Assets/MainPage/IntroPageImg/frame services.webp"
          hoverClass={"containerContent5"}
          moreDetail={{
            backgroundcolor: " rgba(112, 110, 230, 1)",
            color: "#FFF",
          }}
        />
      </Element>
      <Element
        name="section8"
        className={styles.section}
        id="section8"
        ref={sectionRefs.section8}
      >
        <SectorCard
          id="6"
          title="Petcare"
          tagline="  Join Our Network of Top-Tier Pet Care Professionals"
          content="We're excited to introduce you to our company and the
                  wonderful world of pet care. Whether you're a pet owner
                  looking for top-notch services or a pet lover interested in
                  joining our community, you've come to the right place. Let's
                  dive in and explore what we have to offer!'"
          image="/Assets/MainPage/IntroPageImg/frame petcare.webp"
          hoverClass={"containerContent6"}
          moreDetail={{
            backgroundcolor: "rgba(207, 118, 221, 1)",
            color: "#FFF",
          }}
        />
      </Element>

      {/* ---------------------------------------------Enquire now--------------------------------------------- */}

      <Element
        name="section9"
        className={styles.section}
        style={{ textAlign: "left", marginTop: "4rem" }}
        id="section9"
        ref={sectionRefs.section9}
      >
        <div
          className={styles_getStarted.cont}
          style={{ background: "#122577", marginTop: "4rem" }}
        >
          <section
            className={`${styles_getStarted.cont__inside} ${styles.cont__inside}`}
          >
            <section className={styles_getStarted.cont__inside__left}>
              <section
                style={{ width: "70%" }}
                className={`${styles_getStarted.cont__inside__left__inside} `}
              >
                <h2>Your All-in-One App for Everday Needs!</h2>
                <p>
                  From construction to healthcare, entertainment to petcare.
                  CONNECT brings a network of trusted right to yourfingertips.
                </p>
                <Link
                  to={"https://forms.gle/EFU47rgSkHYgXZyH6"}
                  target="_blank"
                >
                  <button style={{ background: " #5359FF", color: "#fff" }}>
                    Enquire Now
                  </button>
                </Link>
              </section>
            </section>
            <section className={`${styles_getStarted.cont__inside__right} `}>
              <img
                src="/Assets/MainPage/IntroPageImg/map.svg"
                style={{ position: "relative", right: "-10.5%", bottom: "-2%" }}
                className={styles_getStarted.largeImage}
              />
              <img
                src="/Assets/MainPage/IntroPageImg/smallScreenMap.webp"
                style={{ position: "relative", bottom: "-2%" }}
                className={`${styles_getStarted.smallImage} `}
              />
            </section>
          </section>
        </div>
      </Element>

      {/* ---------------------------------------------footer--------------------------------------------- */}

      <Element
        name="section10"
        // className={styles.section}
        style={{ textAlign: "left" }}
        id="section10"
        ref={sectionRefs.section10}
      >
        <Footer
          contactColor="#5359FF"
          iconsColor="#fff"
          iconsBgColor=" #5359FF"
          isMain={true}
        // handleScroll ={handleScroll}
        />
      </Element>
    </div>
  );
};

export default Intro;
