export const ConstructionOurProcessData = [
  {
    image: "/Assets/Construction/Images/img27.webp",
    polygon: "/Assets/Construction/Images/Polygon1.webp",
    color: "#6f6fbd",
    backgroundColor: "#ececfc",
    info: {
      heading: "Onboarding",
      content:
        " We start by inviting skilled professionals to join our platform. This involves a thorough screening process to verify their qualifications, experience, and commitment to quality. Service providers undergo background checks and provide proof of licensure and insurance, ensuring trust and reliability for our customers.",
    },
  },
  {
    image: "/Assets/Construction/Images/img28.webp",
    polygon: "/Assets/Construction/Images/Polygon2.webp",
    color: "#65acc2",
    backgroundColor: "#E4F8F7",
    info: {
      heading: "Matchmaking",
      content:
        "Once onboarded, we analyze the specific needs of our customers with precision. Our advanced algorithms and personalized approach match each project with the most suitable service provider. Whether it's a large-scale construction project or a minor renovation, we ensure the perfect fit between the expertise of our professionals and the requirements of the job.",
    },
  },
  {
    image: "/Assets/Construction/Images/img29.webp",
    polygon: "/Assets/Construction/Images/Polygon3.webp",
    color: "#ae676c",
    backgroundColor: "#fdedee",
    info: {
      heading: "Service Completion",
      content:
        "With the match made, our service providers spring into action, delivering top-notch services tailored to our customer's requirements. Their expertise and dedication ensure the completion of projects to the highest standards. ",
    },
  },

  {
    image: "/Assets/Construction/Images/img30.webp",
    polygon: "/Assets/Construction/Images/Polygon4.webp",
    color: "#a3905d",
    backgroundColor: "#fbf6e8",
    info: {
      heading: "Customer Satisfaction",
      content:
        " Our ultimate goal is the satisfaction of our customers. We prioritize their needs and feedback throughout the process,ensuring that every project concludes with smiles all around.",
    },
  },
  {
    image: "/Assets/Construction/Images/img31.webp",
    polygon: "/Assets/Construction/Images/Polygon5.webp",
    color: "#65c269",
    backgroundColor: "#e4f6e5",
    info: {
      heading: "Post-Service Support",
      content:
        "Our commitment to customer satisfaction doesn't end with the completion of the project. We offer comprehensive post-service support, including warranties and follow-up consultations, to ensure long-term peace of mind. Whether it's addressing maintenance issues or providing recommendations for future enhancements, we remain dedicated to our customers even after the job is done. ",
    },
  },
];

export const EntertainmentOurProcessData = [
  {
    image: "/Assets/Entertainment/Images/img20.webp",
    polygon: "/Assets/Entertainment/Images/Polygon1.webp",
    color: "#8B633F",
    backgroundColor: "#FFD8B4",
    shortContent: true,
    info: {
      heading: "Streamlined Setup",
      content:
        " A simple onboarding process to get our users up and running quickly. ",
    },
  },
  {
    image: "/Assets/Entertainment/Images/img21.webp",
    polygon: "/Assets/Entertainment/Images/Polygon2.webp",
    color: "#82418C",
    backgroundColor: "#F6B8FF",
    shortContent: true,
    info: {
      heading: "Collaboration & Communication",
      content:
        "Enabling seamless interaction among users, facilitating teamwork and information exchange. ",
    },
  },
  {
    image: "/Assets/Entertainment/Images/img22.webp",
    polygon: "/Assets/Entertainment/Images/Polygon3.webp",
    color: "#893D4A",
    backgroundColor: "#FFB5C2",
    shortContent: true,
    info: {
      heading: "Opportunities & Visibility",
      content:
        "Providing a platform where you can discover opportunities and gain visibility for your work or projects. ",
    },
  },
  {
    image: "/Assets/Entertainment/Images/img23.webp",
    polygon: "/Assets/Entertainment/Images/Polygon4.webp",
    color: "#718133",
    backgroundColor: "#EFFFAF",
    shortContent: true,
    info: {
      heading: "Monetisation Features",
      content:
        " Offering tools or features that allow users to monetise their efforts or creations effectively. ",
    },
  },
  {
    image: "/Assets/Entertainment/Images/img24.webp",
    polygon: "/Assets/Entertainment/Images/Polygon5.webp",
    color: "#3F3A80",
    backgroundColor: "#BDB8FF",
    shortContent: true,
    info: {
      heading: "Marketing Support",
      content:
        "Assisting users in promoting their offerings or services through built-in marketing tools. ",
    },
  },
  {
    image: "/Assets/Entertainment/Images/img25.webp",
    polygon: "/Assets/Entertainment/Images/Polygon6.webp",
    color: "#824D38",
    backgroundColor: "#FFCCB7",
    shortContent: true,
    info: {
      heading: "Dedicated Support Team",
      content:
        " Providing a dedicated support team to assist users with any issues or queries they may have.",
    },
  },
];

export const EventOurProcessData = [
  {
    image: "/Assets/Events/Images/img23.webp",
    polygon: "/Assets/Events/Images/Polygon1.webp",
    color: "#FECE26",
    backgroundColor: "#FBF0C9",
    shortContent: true,
    info: {
      heading: "Onboarding",
      content: "Create your profile and join our community.",
    },
  },
  {
    image: "Assets/Events/Images/img24.webp",
    polygon: "/Assets/Events/Images/Polygon2.webp",
    color: "#FF3CFF",
    backgroundColor: "#FFEBFF",
    shortContent: true,
    info: {
      heading: "Explore",
      content: "Browse through our extensive list of services and vendors.",
    },
  },
  {
    image: "/Assets/Events/Images/img25.webp",
    polygon: "/Assets/Events/Images/Polygon3.webp",
    color: "#3F3FFF",
    backgroundColor: "#E4E4FF",
    shortContent: true,
    info: {
      heading: "Connect",
      content:
        "Communicate directly with vendors and teams to discuss your event needs.",
    },
  },
  {
    image: "/Assets/Events/Images/img26.webp",
    polygon: "/Assets/Events/Images/Polygon4.webp",
    color: "#FF3636",
    backgroundColor: "#FDEDED",
    shortContent: true,
    info: {
      heading: "Service completion",
      content: "Secure the services you need with our easy booking system.",
    },
  },

  {
    image: "/Assets/Events/Images/img27.webp",
    polygon: "/Assets/Events/Images/Polygon5.webp",
    color: "#FFA525",
    backgroundColor: "#FFEFD8",
    shortContent: true,
    info: {
      heading: "Post-Service Support",
      content: "Access our support team anytime for assistance. ",
    },
  },
];

export const HealthCareOurProcessData = [
  {
    image: "/Assets/Healthcare/Images/img27.webp",
    polygon: "/Assets/Healthcare/Images/Polygon1.webp",
    color: "#6F6FBD",
    backgroundColor: "#ECECFC",
    info: {
      heading: "Onboarding",
      content:
        "We start by inviting skilled professionals to join our platform. This involves a thorough screening process to verify their qualifications, experience, and commitment to quality. Service providers undergo background checks and provide proof of licensure and insurance, ensuring trust and reliability for our customers.",
    },
  },

  {
    image: "/Assets/Healthcare/Images/img28.webp",
    polygon: "/Assets/Healthcare/Images/Polygon2.webp",
    color: "#65ACC2",
    backgroundColor: "#E4F8F7",
    info: {
      heading: "Matchmaking",
      content:
        "Once onboarded, we analyze the specific needs of our customers with precision. Our advanced algorithms and personalized approach match each project with the most suitable service provider. Whether it's a large-scale construction project or a minor renovation, we ensure the perfect fit between the expertise of our professionals and the requirements of the job.",
    },
  },
  {
    image: "/Assets/Healthcare/Images/img29.webp",
    polygon: "/Assets/Healthcare/Images/Polygon3.webp",
    color: "#AE676C",
    backgroundColor: "#FDEDEE",
    info: {
      heading: "Service Completion",
      content:
        "With the match made, our service providers spring into action, delivering top-notch services tailored to our customers' requirements. Their expertise and dedication ensure the completion of projects to the highest standards.",
    },
  },
  {
    image: "/Assets/Healthcare/Images/img30.webp",
    polygon: "/Assets/Healthcare/Images/Polygon4.webp",
    color: "#A3905D",
    backgroundColor: "#FBF6E8",
    info: {
      heading: "Customer Satisfaction",
      content:
        "Our ultimate goal is the satisfaction of our customers. We prioritize their needs and feedback throughout the process, ensuring that every project concludes with smiles all around.",
    },
  },

  {
    image: "/Assets/Healthcare/Images/img31.webp",
    polygon: "/Assets/Healthcare/Images/Polygon5.webp",
    color: "#67B96D",
    backgroundColor: "#EBFAEC",
    info: {
      heading: "Post-Service Support",
      content:
        "Our commitment to customer satisfaction doesn't end with the completion of the project. We offer comprehensive post-service support, including warranties and follow-up consultations, to ensure long-term peace of mind. Whether it's addressing maintenance issues or providing recommendations for future enhancements, we remain dedicated to our customers even after the job is done.",
    },
  },
];

export const HomeServicesOurProcessData = [
  {
    image: "/Assets/HomeServices/Images/img29.webp",
    polygon: "/Assets/HomeServices/Images/Polygon5.webp",
    color: "#C26565",
    backgroundColor: "#FAE9E9",
    info: {
      heading: "Onboarding",
      content:
        " At CONNECT, we meticulously onboard professional service providers through a rigorous screening and background check process. This ensures that we partner with only the best, most reliable experts who are committed to delivering top-quality services to our users.",
    },
  },

  {
    image: "/Assets/HomeServices/Images/img30.webp",
    polygon: "/Assets/HomeServices/Images/Polygon4.webp",
    color: "#65ACC2",
    backgroundColor: "#E0F0F5",
    info: {
      heading: "Matchmaking",
      content:
        "Once onboarded, our advanced backend algorithm analyzes users' needs in real-time. This allows us to match each user with the most suitable service provider, ensuring a perfect fit based on their specific requirements and preferences. ",
    },
  },
  {
    image: "/Assets/HomeServices/Images/img31.webp",
    polygon: "/Assets/HomeServices/Images/Polygon3.webp",
    color: "#656EC2",
    backgroundColor: "#E9EBFC",
    info: {
      heading: "Service Completion",
      content:
        "After a successful match, our service providers deliver top-notch, tailored services directly to our users. We pride ourselves on offering personalized solutions that exceed expectations and meet each user's unique needs. ",
    },
  },
  {
    image: "/Assets/HomeServices/Images/img32.webp",
    polygon: "/Assets/HomeServices/Images/Polygon2.webp",
    color: "#C265B3",
    backgroundColor: "#FAE8F7",
    info: {
      heading: "Customer Satisfaction",
      content:
        "Our primary goal is to achieve the highest level of customer satisfaction. We are dedicated to providing exceptional services that leave our customers delighted and eager to use our services again. ",
    },
  },

  {
    image: "/Assets/HomeServices/Images/img33.webp",
    polygon: "/Assets/HomeServices/Images/Polygon1.webp",
    color: "#65C269",
    backgroundColor: "#E4F6E5",
    info: {
      heading: "Post-Service Support",
      content:
        "Our commitment to excellence continues even after the service is completed. We invite our customers to provide feedback through a convenient form, allowing us to continually improve and refine our services based on their valuable insights. ",
    },
  },
];

export const PetcareOurProcessData = [
  {
    image: "/Assets/Petcare/Images/img26.webp",
    polygon: "/Assets/Petcare/Images/Polygon5.webp",
    color: "#C26565",
    backgroundColor: "#FAE9E9",
    info: {
      heading: "Onboarding",
      content:
        "We invite skilled professionals to join our platform, ensuring they meet our high standards through screening and background checks. Service providers provide proof of licensure and insurance, ensuring trust and reliability for our customers. ",
    },
  },
  {
    image: "/Assets/Petcare/Images/img27.webp",
    polygon: "/Assets/Petcare/Images/Polygon4.webp",
    color: "#65ACC2",
    backgroundColor: "#E0F0F5",
    info: {
      heading: "Matchmaking",
      content:
        "We analyze the specific needs of our customers and match them with the right service providers using advanced algorithms and personalized approaches, ensuring the perfect fit for every pet care need. ",
    },
  },
  {
    image: "/Assets/Petcare/Images/img28.webp",
    polygon: "/Assets/Petcare/Images/Polygon3.webp",
    color: "#656EC2",
    backgroundColor: "#E9EBFC",
    info: {
      heading: "Service Completion",
      content:
        " Our service providers deliver top-notch services tailored to your requirements. Their expertise and dedication ensure high-quality outcomes for all pet care services.",
    },
  },
  {
    image: "/Assets/Petcare/Images/img29.webp",
    polygon: "/Assets/Petcare/Images/Polygon2.webp",
    color: "#C265B3",
    backgroundColor: "#FAE8F7",
    info: {
      heading: "Customer Satisfaction",
      content:
        "We prioritize your needs and feedback, ensuring every project concludes with satisfaction. Our goal is to exceed your expectations in every interaction. ",
    },
  },
  {
    image: "/Assets/Petcare/Images/img30.webp",
    polygon: "/Assets/Petcare/Images/Polygon1.webp",
    color: "#65C269",
    backgroundColor: "#E4F6E5",
    info: {
      heading: "Post-Service Support",
      content:
        "Our commitment to customer satisfaction doesn't end with the completion of the service. At Connect, we offer comprehensive post-service support to ensure long-term peace of mind for pet owners. ",
    },
  },
];
