import styles from "./AboutOpportunities.module.css";

const AboutOpportunities = ({ data }) => {
  console.log(data);
  return (
    <div className={styles.aboutOpportunities}>
      {data.map((opportunity, index) => (
        <div
          key={index}
          className={
            index % 2 === 0
              ? ` ${styles.opportunity_section} `
              : `${styles.opportunity_section_even} ${styles.opportunity_section}`
          }
        >
          {" "}
          <img
            src={opportunity?.image}
            className={styles.pet_image}
            alt="..."
          />
          <div
            className={
              index % 2 === 0
                ? `${styles.opportunity_right_section} ${styles.left}`
                : `${styles.opportunity_right_section} ${styles.right}`
            }
          >
            {opportunity.info?.map(({ heading, content, icon }) => (
              <div className={styles.opportunity_right_section_info}>
                <div className={styles.info_top}>
                  <img src={icon} className={styles.icon} alt="..." />
                  <h5 className={styles.heading}>{heading}</h5>
                </div>
                <p className={styles.content}>{content}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AboutOpportunities;
