import styles from "../styles.module.css";

const TermsAndConditions = ({ show, onClose }) => {
    // if (show !== "terms") {
    //     return null;
    // }
    return (
        <div 
        // className={styles.parent}
        >
            <div className={styles.child}>
                {/* <span className={styles.close} onClick={onClose}>
                    x
                </span> */}
                <h2>Terms and Conditions for Service Providers</h2>
                <h3>Effective Date: [Insert Date]</h3>
                <p>
                    Welcome to Connect! These Terms and Conditions ("Terms") govern your
                    use of the Connect platform as a service provider across various
                    sectors. By listing yourself on Connect and providing services to
                    customers, you agree to comply with these Terms.
                </p>

                <p className={styles.heading}>1. Eligibility and Registration</p>
                <p className={styles.heading}>1.1 Eligibility Criteria:</p>
                <ul className={styles.subList}>
                    <li>
                        You must be legally eligible to offer services in your sector within
                        your operating jurisdiction, including possessing any required
                        licenses, permits, and insurance.
                    </li>
                    <li>
                        You must be of legal age to enter into contracts and be capable of
                        fulfilling the obligations under these Terms.
                    </li>
                </ul>

                <p className={styles.heading}>1.2 Registration Process:</p>
                <ul className={styles.subList}>
                    <li>
                        Complete the registration process by providing accurate and truthful
                        information about your services, qualifications, and experience.
                    </li>
                    <li>
                        You are responsible for maintaining the accuracy and completeness of
                        your profile, including service descriptions, availability, pricing,
                        and contact information.
                    </li>
                </ul>

                <p className={styles.heading}>1.3 Profile Maintenance:</p>
                <ul className={styles.subList}>
                    <li>
                        Regularly update your profile to reflect any changes in your
                        services, availability, or qualifications.
                    </li>
                    <li>
                        Notify Connect of any significant changes that could affect your
                        service provision or compliance with these Terms.
                    </li>
                </ul>

                <p className={styles.heading}>2. Scope of Services</p>
                <p className={styles.heading}>2.1 Service Categories:</p>
                <ul className={styles.subList}>
                    <li>
                        <b>Construction:</b> Contractors, professionals, and laborers for
                        various construction tasks.
                    </li>
                    <li>
                        <b>Entertainment:</b> Performers, DJs, magicians, and entertainment
                        professionals.
                    </li>
                    <li>
                        <b>Events:</b> Event planners, decorators, caterers, photographers,
                        and more.
                    </li>
                    <li>
                        <b>Pet Care:</b> Pet sitters, groomers, trainers, walkers, and other
                        pet services.
                    </li>
                    <li>
                        <b>Home Care:</b> Caregivers, cleaners, handymen, and home
                        maintenance services.
                    </li>
                    <li>
                        <b>Healthcare:</b> Doctors, nurses, wellness specialists, and
                        healthcare professionals.
                    </li>
                </ul>

                <p className={styles.heading}>2.2 Service Provider Responsibilities:</p>
                <ul className={styles.subList}>
                    <li>
                        Ensure services are provided in accordance with the descriptions and
                        terms listed in your profile.
                    </li>
                    <li>
                        Maintain professional standards and ensure that services meet
                        industry norms and customer expectations.
                    </li>
                </ul>

                <p className={styles.heading}>2.3 Service Delivery:</p>
                <ul className={styles.subList}>
                    <li>
                        Deliver services in a timely manner as agreed with the customer.
                    </li>
                    <li>
                        Address any issues or complaints raised by customers promptly and
                        professionally.
                    </li>
                </ul>

                <p className={styles.heading}>3. Service Listings and Bookings</p>
                <p className={styles.heading}>3.1 Listing Accuracy:</p>
                <ul className={styles.subList}>
                    <li>
                        Ensure that all service listings are clear, accurate, and
                        up-to-date.
                    </li>
                </ul>

                <p className={styles.heading}>3.2 Booking Management:</p>
                <ul className={styles.subList}>
                    <li>
                        Accept or reject service requests based on availability and
                        feasibility.
                    </li>
                    <li>
                        Notify Connect and the customer promptly if you need to cancel or
                        reschedule a booking.
                    </li>
                </ul>

                <p className={styles.heading}>4. Payment and Fees</p>
                <p className={styles.heading}>4.1 Payment Processing:</p>
                <ul className={styles.subList}>
                    <li>
                        Payments for services will be processed through the Connect
                        platform.
                    </li>
                </ul>

                <p className={styles.heading}>4.2 Service Fees:</p>
                <ul className={styles.subList}>
                    <li>
                        Connect may charge a service fee or commission on payments
                        processed.
                    </li>
                </ul>

                <p className={styles.heading}>
                    5. Compliance with Laws and Regulations
                </p>
                <p className={styles.heading}>5.1 Legal Compliance:</p>
                <ul className={styles.subList}>
                    <li>
                        Service Providers must comply with all applicable laws and
                        regulations relevant to their sector.
                    </li>
                </ul>

                <p className={styles.heading}>5.2 Insurance Requirements:</p>
                <ul className={styles.subList}>
                    <li>
                        Ensure appropriate insurance coverage for services, including
                        liability insurance where applicable.
                    </li>
                </ul>

                <p className={styles.heading}>6. Confidentiality and Data Privacy</p>
                <p className={styles.heading}>6.1 Confidentiality:</p>
                <ul className={styles.subList}>
                    <li>
                        Maintain the confidentiality of any sensitive information shared by
                        customers.
                    </li>
                </ul>

                <p className={styles.heading}>6.2 Data Protection:</p>
                <ul className={styles.subList}>
                    <li>
                        Implement appropriate measures to protect the security of customer
                        data.
                    </li>
                </ul>

                <p className={styles.heading}>
                    7. Standard of Service and Quality Assurance
                </p>
                <ul className={styles.subList}>
                    <li>
                        Provide services to the highest professional standards and ensure
                        customer satisfaction.
                    </li>
                </ul>

                <p className={styles.heading}>
                    8. Professional Conduct and Ethical Standards
                </p>
                <ul className={styles.subList}>
                    <li>
                        Interact with customers in a professional and respectful manner.
                    </li>
                </ul>

                <p className={styles.heading}>9. Termination and Suspension</p>
                <ul className={styles.subList}>
                    <li>
                        Connect reserves the right to suspend or terminate your account for
                        violations of these Terms.
                    </li>
                </ul>

                <p className={styles.heading}>10. Dispute Resolution</p>
                <ul className={styles.subList}>
                    <li>
                        Disputes will be handled in accordance with Connect’s dispute
                        resolution procedures.
                    </li>
                </ul>

                <p className={styles.heading}>11. Liability and Indemnification</p>
                <ul className={styles.subList}>
                    <li>
                        Service Providers are responsible for any damages or losses arising
                        from the services they provide.
                    </li>
                </ul>

                <p className={styles.heading}>12. Modifications to Terms</p>
                <ul className={styles.subList}>
                    <li>Connect reserves the right to modify these Terms at any time.</li>
                </ul>

                <p className={styles.heading}>13. Governing Law</p>
                <ul className={styles.subList}>
                    <li>These Terms shall be governed by the laws of [Jurisdiction].</li>
                </ul>

                <p className={styles.heading}>14. Contact Information</p>
                <ul className={styles.subList}>
                    <li>
                        If you have any questions, please contact Connect support at
                        [Support Email].
                    </li>
                </ul>
                <p className={styles.para_heading}>Account Creation</p>
                <div className={styles.section}>
                    <p>(A) To avail the Services and provide the Pro Services through the Platform, you will be required to create an account on the Platform ("Account"). In addition to setting up a username and password for your Account, you will be required to furnish certain details and documents, including but not limited to your name, phone number, address, age, valid government-issued Identification, trade licenses, and government approvals that permit you to offer Pro Services under applicable law. To create an Account, you must be at least 18 (Eighteen) years of age.</p>

                    <p>(B) You warrant that all information furnished in connection with your Account is and shall remain accurate and true. You agree to promptly update your details on the Platform in the event of any change to or modification of this information.</p>

                    <p>(C) You agree that CONNECT, through third parties, may undertake your background verification, at your sole cost, to fulfill due diligence and safety obligations prior to the approval of your Account on the Platform, activating your access to the Services, and permitting you to provide Pro Services through the Services. In addition, CONNECT, through third parties, may, at its sole discretion and at your sole cost, undertake such background verification from time to time thereafter. If you are registered on the Platform prior to the completion of the background verification, your registration on the Platform shall be contingent on your background verification report meeting CONNECT’s standards (as CONNECT may determine in accordance with applicable laws and its internal policies). You agree that your ability to use the Services and offer Pro Services through the Platform shall at all times be subject to your background verification reporting meeting CONNECT’s standards. For the avoidance of doubt, CONNECT takes commercially reasonable efforts to undertake background verifications of Service Professionals and is not responsible or liable for ensuring the safety, security, or welfare of the Service Professionals or the Customers.</p>

                    <p>(D) You may only own, operate, and possess one Account. If CONNECT discovers or determines that you possess more than one Account, it will have the right to revoke, without notice, your access to the Platform and the Services.</p>
                    <p>(E) You are solely responsible for maintaining the security and confidentiality of your password and agree to immediately notify us of any disclosure or unauthorized use of your Account or any other breach of security with respect to your Account. You further agree that no person other than yourself has the right to access your Account, and you will not share your login credentials with any other person. If CONNECT reasonably determines that you have shared your login credentials with another person or allowed another person to access and use your Account, it may suspend or terminate your access and use of the Services without notice to you.</p>
                    <p>(F) You are liable and accountable for all activities that take place through your Account. We shall not be liable for any unauthorized access to your Account.</p>
                    <p>(G) You agree to receive communications from us regarding:</p>
                    <ul>
                        <li>(i) Information about us and the Services;</li>
                        <li>(ii) Promotional offers and services from us and our third-party partners; and</li>
                        <li>(iii) Any other matter in relation to the Services.</li>
                    </ul>
                </div>

                <p className={styles.para_heading}>Booking</p>
                <div className={styles.section}>
                    <p>(A) The Platform permits you to accept requests for the Pro Services you offer based on your availability ("Leads"). To confirm a request, you should follow the instructions on the Platform and provide the necessary information.</p>
                    <p>(B) Ranking of Service Professionals: Your ranking on the Platform is based on the following factors in descending order of importance:</p>
                    <ul>
                        <li>(i) Your ratings on the Platform</li>
                        <li>(ii) Your location</li>
                        <li>(iii) The number of customers you’ve already served</li>
                    </ul>
                </div>

                <p className={styles.para_heading}>Helpers</p>
                <div className={styles.section}>
                    <p>(A) Helpers or Assistants: You agree and acknowledge that:</p>
                    <ul>
                        <li>(i) You may not engage another person to assist you in the provision of the Pro Services (“Helper") unless expressly permitted by Connect. You shall be solely liable for all acts and omissions made by the Helper you have engaged. The following terms apply if you choose to appoint a Helper:</li>
                        <ul>
                            <li>- You must provide Pro Services in a category where the engagement of Helpers is permitted by Connect.</li>
                            <li>- The Helper must be registered on the Platform in their capacity as your Helper. Connect shall have the right to demand documentation governing the applicant's birth, residency, and any other documents or information that Connect may deem necessary. Connect further reserves the right to carry out a background verification of the Helper, the cost of which shall be borne by you.</li>
                            <li>- The Helper is at least 18 (Eighteen) years of age at the time of registration on the Platform.</li>
                            <li>- For safety purposes, the Helpers must be of the same biological sex as you.</li>
                            <li>- The Helper must be legally permitted to assist in the provision of Services to the Customers.</li>
                            <li>- Such other terms and conditions prescribed by Connect from time to time.</li>
                        </ul>
                        <li>(ii) Unless specified otherwise, you may not use more than 1 (One) Helper while providing a Pro Service to a Customer.</li>
                        <li>(iii) You shall accompany the Helper, in person, at all times, while providing a Pro Service to a Customer.</li>
                        <li>(iv) Any breach of these Terms by the Helper shall be considered a breach by you and shall be dealt with accordingly in accordance with these Terms. Further, you shall indemnify and hold harmless Connect and its affiliates and their officers, directors, employees, and agents from any and all claims, demands, losses, liabilities, and expenses (including attorneys' fees) arising out of or in connection with any act or omission by the Helper.</li>
                        <li>(v) You agree that there is no contract of employment between Connect and the Helper and that you have engaged the Helper in your capacity as an independent contractor.</li>
                        <li>(vi) You shall be solely liable for compensating the Helper on the terms and conditions agreed upon between you and the Helper. Connect shall not be liable to compensate the Helper for the provision of the Pro Services or any expense incurred or assistance provided by the Helper to you in the provision of the Pro Services. Further, Connect shall not be liable to pay you any extra compensation for engaging a Helper.</li>
                        <li>(vii) Your engagement of a Helper shall not be construed as an assignment, transfer, delegation, sub-delegation, or subcontracting of your obligations under these Terms. In any event, the primary responsibility and liability to perform your obligations under these Terms shall vest with you.</li>
                    </ul>
                    <p>(B) For the avoidance of doubt, it is hereby clarified that nothing contained in these Terms shall be construed as Connect encouraging, advocating for, or discouraging you to engage a Helper.</p>
                </div>

                <p className={styles.para_heading}>Conduct</p>
                <div className={styles.section}>
                    <p>(A) Connect prohibits discrimination against Customers on the basis of race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age, or any other characteristic that may be protected under applicable law. Such discrimination includes but is not limited to any refusal to provide the Pro Services based on any of these characteristics.</p>
                    <p>(B) We request that you treat all Customers with courtesy and respect. We reserve the right to withhold access to the Services at our absolute discretion if you behave towards any Customer in a manner that is discourteous, disrespectful, abusive, or which we otherwise deem to be inappropriate or unlawful.</p>
                </div>

                <p className={styles.para_heading}>Consent to Use Data</p>
                <div className={styles.section}>
                    <p>(A) You agree that we may, in accordance with our Privacy Policy, collect and use your personal data. The Privacy Policy explains the categories of personal data that we collect or otherwise process about you and the manner in which we process such data.</p>
                    <p>(B) In certain instances, you may be required to furnish identification proof to avail the Services or to provide the Pro Services, and hereby agree to do so. A failure to comply with this request may result in your inability to use the Services or provide the Pro Services.</p>
                    <p>(C) In addition to any consent you may provide pursuant to the Privacy Policy, you hereby consent to us sharing your information with our affiliates or other third-party service providers. We may use information and data pertaining to your use of the Services for the provision of the Services, analytics, trend identification, and purposes of statistics to further enhance the effectiveness and efficiency of our Services.</p>
                    <p>(D) Subject to applicable laws, we may be directed by law enforcement agencies or the government and related bodies to disclose data in relation to you in connection with criminal proceedings. You understand and agree that in such instances we shall have the right to share such data with relevant agencies or bodies.</p>
                </div>
                <p className={styles.para_heading}>Third Party Services</p>
                <div className={styles.section}>
                    <p>(A) The Platform may include services, content, documents, and information owned by, licensed to, or otherwise made available by a third party ("Third Party Services") and contain links to Third Party Services. You understand and acknowledge that Third Party Services are the sole responsibility of the third party that created or provided it and that use of such Third Party Services is solely at your own risk.</p>
                    <p>(B) We make no representations and exclude all warranties and liabilities arising out of, or pertaining to, such Third Party Services, including their accuracy or completeness. Should you avail a Third Party Service, you shall be governed and bound by the terms and conditions, and privacy policy of the third parties providing the Third Party Services. Further, all intellectual property rights in, and to Third Party Services, are the property of the respective third parties.</p>
                </div>

                <p className={styles.para_heading}>Your Responsibilities</p>
                <div className={styles.section}>
                    <p>(A) You represent and warrant that all information that you provide in relation to the Services is complete, true, and correct on the date of agreeing to these Terms and shall continue to be complete, true, and correct while you avail the Services. Should any information that you provide change during the existence of these Terms, you undertake to immediately bring such change to our notice. We do not accept any responsibility or liability for any loss or damage that you may suffer or incur if any information, documentation, material, or data, provided to avail the Services is incorrect, incomplete, inaccurate, or misleading, or if you fail to disclose any material fact.</p>
                    <p>(B) You shall extend all cooperation to us in our defense of any proceedings that may be initiated against us due to a breach of your obligations or covenants under these Terms.</p>
                    <p>(C) In respect of the User Content, you represent and warrant that:</p>
                    <ul>
                        <li>(i) You will not provide feedback for services that you have performed in your capacity as a Service Professional;</li>
                        <li>(ii) You own all intellectual property rights (or have obtained all necessary permissions) to provide User Content and to grant the licenses under these Terms;</li>
                        <li>(iii) You are solely responsible for all activities that occur on or through your account on the Platform and all User Content;</li>
                        <li>(iv) The User Content does not and shall not violate any of your obligations or responsibilities under other agreements;</li>
                        <li>(v) The User Content does not and shall not violate, infringe, or misappropriate any intellectual property right or other proprietary right including the right of publicity or privacy of any person or entity;</li>
                        <li>(vi) The User Content does not and shall not contain any viruses, corrupted data, or other harmful, disruptive, or destructive files or content;</li>
                        <li>(vii) The User Content does not and shall not violate any third party rights;</li>
                        <li>(viii) The User Content (A) does not belong to any other person to which you do not have any right, (B) does not threaten the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, public order, cause incitement to the commission of any cognisable offense, prevents investigation of any offense, or is insulting another nation, (C) is not defamatory, grossly harmful, blasphemous, paedophilic, invasive of another's privacy, discriminatory based on gender, ethnically objectionable, disparaging, relating to, or encouraging money laundering or gambling, libelous, hateful, racist, violent, obscene, pornographic, unlawful, harmful to children, or (D) is not otherwise offensive, objectionable, or restricts, or inhibits, any other person from using or enjoying the Services;</li>
                    </ul>
                    <p>(D) You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting the generality of the preceding sentence, you shall not:</p>
                    <ul>
                        <li>(i) Infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of any party;</li>
                        <li>(ii) Except as may be provided hereunder, copy, display, distribute, modify, publish, reproduce, store, transmit, post, translate, create any derivative works from or license the Services;</li>
                        <li>(iii) Use the Services to transmit any data, or send or upload any material that contains viruses, trojan horses, worms, timebombs, keystroke loggers, spyware, adware, or any other harmful programmes, or similar computer code, designed to adversely affect the operation of any computer software or hardware;</li>
                        <li>(iv) Use any robot, spider, other automated device, or manual process to monitor or copy the Services or any portion thereof;</li>
                        <li>(v) Engage in the systematic retrieval of content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory;</li>
                        <li>(vi) Use the Services in (A) any unlawful manner, (B) for fraudulent or malicious activities or (C) in any manner inconsistent with these Terms;</li>
                        <li>(vii) Decompile, reverse engineer, or disassemble the Services;</li>
                        <li>(viii) Link to, mirror, or frame, any portion of all or any of the Services; or</li>
                        <li>(ix) Violate applicable laws in any manner.</li>
                    </ul>
                    <p>(E) You warrant that you shall not engage in any activity that interferes with or disrupts the Services.</p>
                    <p>(F) You shall not attempt to gain unauthorized access to any portion or feature of the Services, any other systems or networks connected to the Services, to any of our servers, or through the Platform by hacking, password mining, or any other illegitimate means.</p>
                </div>

                <p className={styles.para_heading}>Platform Safety and Anti-fraud Obligations</p>
                <div className={styles.section}>
                    <p>(A) For the safety and privacy of the Customer and the Service Professional and delivery of Pro Service in adherence to the service standards of the Platform, it is necessary that during the Period, any and all Pro Services provided by you to a Customer are provided only through the Platform and in accordance with the terms of these Terms. Any direct or indirect delivery of a Pro Service to a Customer outside of the Platform, whether in whole or in part, and irrespective of whether the request for provision of a Pro Service is initiated by the Customer, would be deemed to be a breach of these Terms and a ground for termination of these Terms, as per the Platform's policies, unless you are able to provide a satisfactory explanation for the same. You agree that this limitation is reasonable, and fair and is necessary for your safety and privacy as well as that of the Customer's and for the protection of the legitimate business interests of CONNECT. However, nothing in this clause shall restrict you from providing the same Pro Service or a similar service to the Customer upon termination of these Terms, by either Party, for any reason whatsoever.</p>
                    <p>(B) Except as mentioned above, nothing in the clause above restricts you from providing same or similar Pro Services during the Period to any person other than a Customer, whether directly or indirectly, either independently or through affiliation with any online or offline business entity engaged in a business similar to CONNECT.</p>
                </div>
                <p className={styles.heading}> Terms and Conditions: Penalties for Service Providers</p>
                <p className={styles.heading}>1. Introduction</p>
                <p className={styles.section}>
                    These terms and conditions outline the penalties applicable to service providers registered with CONNECT (“Company”) for failure to deliver services as per the agreed standards. By signing up as a service provider, you agree to comply with these terms.
                </p>

                <p className={styles.heading}>2. Penalties for Service Delivery Failures</p>

                <p className={styles.subHeading}>2.1. Late Arrival</p>
                <ul className={styles.subList}>
                    <li><b>Definition:</b> A service provider is considered late if they arrive at the customer’s location more than [15] minutes after the scheduled time without prior notice.</li>
                    <li><b>Penalty:</b> A deduction of [X]% from the service fee for the first instance. Repeated late arrivals may result in higher deductions or temporary suspension from the platform.</li>
                </ul>

                <p className={styles.subHeading}>2.2. Incomplete or Unsatisfactory Service</p>
                <ul className={styles.subList}>
                    <li><b>Definition:</b> A service is deemed incomplete or unsatisfactory if the customer reports that the task was not performed according to the agreed-upon standards or was left unfinished.</li>
                    <li><b>Penalty:</b> In such cases, the service provider may be required to revisit the customer to complete the service at no additional charge. Persistent issues may result in a deduction of [X]% from the service fee or permanent removal from the platform.</li>
                </ul>

                <p className={styles.subHeading}>2.3. Misbehavior or Unprofessional Conduct</p>
                <ul className={styles.subList}>
                    <li><b>Definition:</b> Misbehavior includes, but is not limited to, rude or aggressive behavior, harassment, discrimination, or any conduct that is deemed unprofessional by the customer or the Company.</li>
                    <li><b>Penalty:</b> Immediate suspension from the platform pending investigation. If found guilty, the service provider may face permanent removal and potential legal action.</li>
                </ul>

                <p className={styles.subHeading}>2.4. Damage to Property</p>
                <ul className={styles.subList}>
                    <li><b>Definition:</b> Any damage caused to the customer’s property due to negligence or improper handling by the service provider.</li>
                    <li><b>Penalty:</b> The service provider will be responsible for covering the cost of repairs or replacement. Additionally, a penalty of [X]% of the service fee may be imposed, and repeated incidents may lead to suspension or termination of the service provider’s account.</li>
                </ul>

                <p className={styles.heading}>3. Legal Compliance and Liability</p>

                <p className={styles.subHeading}>3.1. Compliance with Laws</p>
                <p className={styles.section}>
                    Service providers must comply with all relevant local, state, and national laws while performing their duties. This includes, but is not limited to, regulations related to labor, safety, and customer privacy.
                </p>

                <p className={styles.subHeading}>3.2. Criminal Activity</p>
                <ul className={styles.subList}>
                    <li><b>Definition:</b> Any criminal activity, including but not limited to theft, fraud, assault, or vandalism, committed by a service provider while on duty or in connection with the services provided.</li>
                    <li><b>Consequence:</b> Immediate termination of the service provider’s account and referral of the matter to the appropriate law enforcement authorities. The Company will not be held responsible for any criminal actions taken by the service provider.</li>
                </ul>

                <p className={styles.subHeading}>3.3. Limitation of Liability</p>
                <p className={styles.section}>
                    The Company shall not be held liable for any acts of misconduct, criminal activities, or negligence committed by the service provider. The service provider is an independent contractor and assumes full responsibility for their actions while delivering services.
                </p>

                <p className={styles.heading}>4. Dispute Resolution</p>

                <p className={styles.subHeading}>4.1. Customer Complaints</p>
                <p className={styles.section}>
                    Any disputes or complaints raised by customers regarding the service provider’s conduct or service quality will be reviewed by the Company. The service provider will have the opportunity to present their side of the story before any penalties are imposed.
                </p>

                <p className={styles.subHeading}>4.2. Appeals Process</p>
                <p className={styles.section}>
                    Service providers have the right to appeal any penalties imposed by submitting a formal request through the app or website. The appeal will be reviewed by the Company’s dispute resolution team, and a final decision will be communicated within [X] business days.
                </p>

                <p className={styles.heading}>5. Amendments and Updates</p>
                <p className={styles.section}>
                    The Company reserves the right to amend or update these terms and conditions at any time. Service providers will be notified of any changes through the app or website, and continued use of the platform constitutes acceptance of the updated terms.
                </p>

                <p className={styles.heading}>6. Acknowledgment and Acceptance</p>
                <p className={styles.section}>
                    By continuing to provide services through the Company’s platform, the service provider acknowledges that they have read, understood, and agree to comply with these terms and conditions.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
