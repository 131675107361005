import { ReactComponent as Logo } from "./Assets/Connect logo LB 1.svg";
import { ReactComponent as MouseScroll } from "./Assets/scroll mouse.svg";
import { ReactComponent as LogoWithoutLine } from "./Assets/Connect logo(Without Tagline) LB 1.svg";
import { ReactComponent as Parallax } from "./Assets/Parallax1.svg";
import { ReactComponent as Line3 } from "./Assets/Line 3.svg";
import { ReactComponent as ConnectLogoWhite } from "./Assets/Connect white logo.svg";
import { ReactComponent as WhatsappIcon } from "./Assets/whatsapp Icon.svg";
import { ReactComponent as DiscordIcon } from "./Assets/discord Icon.svg";
import { ReactComponent as TwitterIcon } from "./Assets/twitter Icon.svg";
import { ReactComponent as LinkedInIcon } from "./Assets/linkedin Icon.svg";
import { ReactComponent as InstaIcon } from "./Assets/insta Icon.svg";
import { ReactComponent as FacebookIcon } from "./Assets/facebook Icon.svg";
import { ReactComponent as ArrowKeyBlack } from "./Assets/arrowKey.svg";
import { ReactComponent as ArrowKeyWhite } from "./Assets/arrowKeyWhite.svg";
import { ReactComponent as ChatLogo } from "./Assets/chat icon.svg";
import { ReactComponent as InstaIconBlue } from "./Assets/insta Icons blue.svg";
import { ReactComponent as LinkedInIconBlue } from "./Assets/linkedIn Icon blue.svg";
import { ReactComponent as TwitterIconBlue } from "./Assets/twitter Icon blue.svg";
import { ReactComponent as WhatsappIconBlue } from "./Assets/whatsapp Icon blue.svg";
import { ReactComponent as DiscordIconBlue } from "./Assets/discord Icon blue.svg";
import { ReactComponent as FacebookIconBlue } from "./Assets/facebook Icon blue.svg";
import { ReactComponent as PhonecallIcon } from "./Assets/phonecall icon.svg";
import { ReactComponent as MailIcon } from "./Assets/mailIcon.svg";
import { ReactComponent as LocationIcon } from "./Assets/location_onIcon.svg";
import { ReactComponent as ConnectLogoNavbar } from "./Assets/Connect logo(Without Tagline) intro navbar.svg";
import { ReactComponent as IndiaMapBackground } from "./Assets/indiaMapBackground.svg";
import { ReactComponent as IndiaMap } from "./Assets/indiaMap.svg";
import { ReactComponent as NotificationIcon } from "./Assets/notificationIcon.svg";
import { ReactComponent as CursorDown } from "./Assets/downCursor.svg";
import { ReactComponent as InfoIcon } from "./Assets/info.svg";
import { ReactComponent as UpsellIcon } from "./Assets/upsellIcon.svg";
import { ReactComponent as GreenVector } from "./Assets/GreenVector.svg";
import { ReactComponent as HamburgerMenuIcon } from "./Assets/Hamburger Menu.svg";
import { ReactComponent as OrderIcon } from "./Assets/orderIcon.svg";
import { ReactComponent as MessageIcon } from "./Assets/messageIcon.svg";
import { ReactComponent as SupportIcon } from "./Assets/supportIcon.svg";
import { ReactComponent as SettingsIcon } from "./Assets/settings.svg";
import { ReactComponent as HomeIcon } from "./Assets/homeIcon.svg";
import { ReactComponent as NavbarCloseIconBlue} from "./Assets/NavbarCloseIconBlue.svg";
import { ReactComponent as HamburgerIconBlue } from "./Assets/HamburgerIconBlue.svg";

export const ICONS = {
  logo: <Logo />,
  mouseScroll: <MouseScroll />,
  logoWithoutLine: <LogoWithoutLine />,
  parallax: <Parallax />,
  line: <Line3 />,
  connectLogoWhite: <ConnectLogoWhite style={{ width: "85%", height: "85%" }} />,
  facebookIcon: <FacebookIcon />,
  whatsappIcon: <WhatsappIcon />,
  discordIcon: <DiscordIcon />,
  twitterIcon: <TwitterIcon />,
  linkedInIcon: <LinkedInIcon />,
  instaIcon: <InstaIcon />,
  facebookIconBlue: <FacebookIconBlue />,
  whatsappIconBlue: <WhatsappIconBlue />,
  discordIconBlue: <DiscordIconBlue />,
  twitterIconBlue: <TwitterIconBlue />,
  linkedInIconBlue: <LinkedInIconBlue />,
  instaIconBlue: <InstaIconBlue />,
  arrowKeyBlack: <ArrowKeyBlack />,
  arrowKeyWhite: <ArrowKeyWhite />,
  chatLogo: <ChatLogo />,
  phonecallIcon: <PhonecallIcon />,
  mailIcon: <MailIcon />,
  location: <LocationIcon />,
  connectLogoNavbar: <ConnectLogoNavbar />,
  indiaMapBackground: (
    <IndiaMapBackground style={{ width: "100%", height: "100%" }} />
  ),
  indiaMap: <IndiaMap style={{ width: "100%", height: "100%" }} />,
  notificationIcon: <NotificationIcon />,
  cursorDown: <CursorDown />,
  infoIcon: <InfoIcon />,
  upsellIcon: <UpsellIcon />,
  greenVector: <GreenVector />,
  hamburgerMenuIcon: <HamburgerMenuIcon />,
  orderIcon: <OrderIcon />,
  messageIcon: <MessageIcon />,
  supportIcon: <SupportIcon />,
  settingsIcon: <SettingsIcon />,
  homeIcon: <HomeIcon />,
  navbarCloseIconBlue: <NavbarCloseIconBlue />,
  hamburgerIconBlue: <HamburgerIconBlue />,
};
