import { useState } from "react";
import { ICONS } from "../../../../../Assets/Icons/index";
import styles from "./Navbar.module.css";

const Navbar = ({
  sticky,
  isScrolled,
  activeSection,
  handleNavClick,
  getStartedBtn,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${styles.navbarParent} ${sticky ? styles.sticky : ""}`}>
      <div
        className={`${styles.introNavbar}  ${
          isScrolled ? styles.fullWidth : ""
        }`}
      >
        <div
          className={`${styles.option1} ${isScrolled ? styles.visible : ""}`}
          onClick={() => getStartedBtn()}
        >
          {ICONS.connectLogoNavbar}
        </div>
        <div className={styles.sectionOptions}>
          <div
            className={`${styles.option} ${
              activeSection === "section3" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section3")}
          >
            Construction
          </div>
          <div
            className={`${styles.option} ${
              activeSection === "section4" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section4")}
          >
            Healthcare
          </div>
          <div
            className={`${styles.option} ${
              activeSection === "section5" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section5")}
          >
            Event
          </div>
          <div
            className={`${styles.option} ${
              activeSection === "section6" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section6")}
          >
            Entertainment
          </div>
          <div
            className={`${styles.option} ${
              activeSection === "section7" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section7")}
          >
            Home Service
          </div>
          <div
            className={`${styles.option} ${
              activeSection === "section8" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section8")}
          >
            Petcare
          </div>
        </div>

        {/* <----------------------- hamber and menu ------------------------------> */}

        <div
          className={`${styles.toggle_btn}`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
           {isOpen? ICONS.navbarCloseIconBlue : ICONS.hamburgerIconBlue}
        </div>  

        <div className={`${styles.dropdown_menu} ${isOpen ? styles.open : ""}`}>
          <div
            className={`${styles.dropdown_option} ${
              activeSection === "section3" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section3")}
          >
            Construction
          </div>
          <div
            className={`${styles.dropdown_option} ${
              activeSection === "section4" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section4")}
          >
            Healthcare
          </div>
          <div
            className={`${styles.dropdown_option} ${
              activeSection === "section5" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section5")}
          >
            Event
          </div>
          <div
            className={`${styles.dropdown_option} ${
              activeSection === "section6" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section6")}
          >
            Entertainment
          </div>
          <div
            className={`${styles.dropdown_option} ${
              activeSection === "section7" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section7")}
          >
            Home Service
          </div>
          <div
            className={`${styles.dropdown_option} ${
              activeSection === "section8" ? styles.active : ""
            }`}
            onClick={() => handleNavClick("section8")}
          >
            Petcare
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
