import styles from "../styles.module.css";

const PrivacyPolicy = ({ show, onClose }) => {
    // if (show !== 'privacy') {
    //     return null;
    // }
    return (
        <div 
        // className={styles.parent}
        >
            <div className={styles.child}>
                {/* <span className={styles.close} onClick={onClose}>
                    x
                </span> */}
                <h2>Privacy Policy for Connect</h2>
                <h3>Introduction</h3>
                <p>
                    This Privacy Policy outlines how Connect Technologies ("Connect",
                    "we", "us", or "our") collects, uses, protects, and shares the
                    personal information of the users ("Users", "you", "you are") of the
                    Connect Super App. This policy applies across all service sectors
                    integrated within the app, including healthcare, homecare,
                    construction, pet care, events, and entertainment.
                </p>

                <p className={styles.heading}>1. Information Collection</p>

                <p className={styles.heading}>1.1 Types of Information Collected</p>

                <ul className={styles.subList}>
                    <li>
                        <b>Personal Information: </b>This may include your name, contact
                        details, date of birth, and identification numbers.
                    </li>
                    <li>
                        <b>Sensitive Information:</b> For sectors like healthcare and
                        homecare, entertainment, petcare, events, and construction this may
                        include health records, treatment information, and other data
                        classified as sensitive under applicable laws.
                    </li>
                    <li>
                        <b>Transactional Information: </b> Details of transactions you carry
                        out through our platform, including financial information.
                    </li>
                    <li>
                        <b>Technical and Usage Information: </b> Information on how you
                        interact with Connect, including IP addresses, device information,
                        and browsing data.
                    </li>
                </ul>
                <p className={styles.heading}>1.2 Methods of Collection</p>
                <ul>
                    <li>
                        <b>Directly from You: </b>Most information is collected directly
                        from you when you register, fill out forms, or interact with our
                        services.
                    </li>
                    <li>
                        <b>Through Technology:</b> We use cookies and similar technologies
                        to collect data on your usage and preferences.
                    </li>
                    <li>
                        <b>Third Parties:</b> Information may also be obtained from other
                        sources, including service partners and public databases.
                    </li>
                </ul>

                <p className={styles.heading}> 2. Use of Information</p>
                <p className={styles.heading}>2.1 Purpose of Collection</p>
                <ul>
                    <li>
                        <b> Service Provision: </b>To provide and manage the services you
                        request across various sectors.
                    </li>
                    <li>
                        <b> Communication:</b>To communicate with you regarding your account
                        and services.
                    </li>
                    <li>
                        <b>Improvement and Personalization:</b> To enhance the functionality
                        and user experience of the app.
                    </li>
                    <li>
                        <b>Legal Compliance:</b> To comply with applicable legal
                        requirements and our policies.
                    </li>
                </ul>
                <p className={styles.heading}> 3. Sharing of Information</p>
                <p className={styles.heading}>3.1 With Whom We Share</p>
                <ul>
                    <li>
                        <b> Service Providers: </b>Information may be shared with third
                        parties who perform services on our behalf, such as payment
                        processing, data analysis, and marketing support.
                    </li>
                    <li>
                        <b>Legal Obligations:</b>We may disclose your information if
                        required by law or in the good faith belief that such action is
                        necessary to comply with a legal obligation, protect and defend our
                        rights or property, and for fraud protection.
                    </li>
                    <li>
                        <b>Improvement and Personalization:</b> To enhance the functionality
                        and user experience of the app.
                    </li>
                    <li>
                        <b>Business Transfers:</b> In the event of a merger, acquisition, or
                        asset sale, your personal information may be transferred.
                    </li>
                </ul>

                <p className={styles.heading}> 4. Data Security</p>
                <p className={styles.heading}>4.1 Security Measures</p>
                <ul>
                    <li>
                        We implement appropriate technical and organizational measures to
                        protect your personal information against unauthorized or unlawful
                        processing, accidental loss, destruction, or damage.
                    </li>
                </ul>
                <p className={styles.heading}> 5. User Rights</p>
                <p className={styles.heading}>5.1 Access and Control</p>
                <ul>
                    <li>
                        <b> Access: </b>You have the right to access the personal
                        information we hold about you.
                    </li>
                    <li>
                        <b>Correction: </b> You can request that any incorrect data we hold
                        about you be corrected.
                    </li>
                    <li>
                        <b>Deletion:</b> Under certain circumstances, you may request the
                        deletion of your information from our records.
                    </li>
                </ul>
                <p className={styles.heading}>6. Cookies and Tracking Technologies</p>
                <ul>
                    <li>
                        We use cookies and similar tracking technologies to track activity
                        on our service and hold certain information. You can instruct your
                        browser to refuse all cookies or to indicate when a cookie is being
                        sent.
                    </li>
                </ul>
                <p className={styles.heading}>7. International Transfers</p>
                <ul>
                    <li>
                        Your information, including personal data, may be transferred to —
                        and maintained on — computers located outside of your state,
                        province, country, or other governmental jurisdiction where the data
                        protection laws may differ from those from your jurisdiction.
                    </li>
                </ul>
                <p className={styles.heading}>8. Changes to This Privacy Policy</p>
                <ul>
                    <li>
                        We may update our Privacy Policy from time to time. We will notify
                        you of any changes by posting the new Privacy Policy on this page.
                    </li>
                </ul>
                <p className={styles.heading}>9. Contact Us</p>
                <ul>
                    <li>
                        If you have any questions about this Privacy Policy, please contact
                        us via email at [privacy@connect.com] or by mail at our business
                        address.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
