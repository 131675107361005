import Styles from "./WhyChooseCard.module.css";

const WhyChooseCard = ({
  imageSource,
  heading,
  content,
  content2,
  fontSize,
  width,
}) => {
  const contentBoldText = content?.split(":");
  const content2BoldText = content2?.split(":");

  return (
    <div
      style={{ width: width }}
      className={`card ${Styles.why_to_choose_card} `}
    >
      <div className="text-center">
        <img
          src={imageSource}
          className={`card-img-top ${Styles.why_to_choose_card_img_top}`}
          alt="..."
        />
      </div>
      <div className={`card-body ${Styles.card_body}`}>
        <h5 className={` ${Styles.card_title} `}>{heading}</h5>
        {contentBoldText.length > 1 ? (
          <p className={Styles.content} style={{ fontSize: fontSize }}>
            <span>
              <b className={Styles.bold}> {contentBoldText[0]}: </b> {contentBoldText[1]}
            </span>
          </p>
        ) : (
          <p className={Styles.content} style={{ fontSize: fontSize }}>
            <span>{content}</span>
          </p>
        )}
        <br/>
        {content2 && (
          <div>
            {content2BoldText.length > 1 ? (
              <p className={Styles.content} style={{ fontSize: fontSize }}>
                <span>
                  <b className={Styles.bold}> {content2BoldText[0]}: </b> {content2BoldText[1]}
                </span>
              </p>
            ) : (
              <p className={Styles.content} style={{ fontSize: fontSize }}>
                {content2}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WhyChooseCard;
