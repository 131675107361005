import { Link } from "react-router-dom";
import Styles from "./OurServiceCard.module.css";

const OurServiceCard = ({
  imageSource,
  heading,
  content,
  content2,
  content3,
  fontSize,
  textAlign,
}) => {
  const contentBoldText = content?.split(":");
  const content2BoldText = content2?.split(":");
  const content3BoldText = content3?.split(":");

  return (
    <div className={`card ${Styles.card}`}>
      <div className="text-center">
        <img
          src={imageSource}
          className={`card-img-top ${Styles.card_img_top} `}
          alt="..."
        />
      </div>
      <div className={`card-body ${Styles.card_body}`}>
        <div className={Styles.bodyUpperSection}>
          <h5 className={Styles.card_title}>{heading}</h5>

          {contentBoldText?.length > 1 ? (
            <p>
              <span className={Styles.content}>
                <b>{contentBoldText[0]}:</b> {contentBoldText[1]}
              </span>
            </p>
          ) : (
            <p>
              <span className={Styles.content}>{content}</span>
            </p>
          )}

          {content2BoldText?.length > 1 ? (
            <p>
              <span className={Styles.content}>
                <b>{content2BoldText[0]}:</b> {content2BoldText[1]}
              </span>
            </p>
          ) : (
            <p>
              <span className={Styles.content}>{content2}</span>
            </p>
          )}
          {content3BoldText?.length > 1 ? (
            <p>
              <span className={Styles.content}>
                <b>{content3BoldText[0]}:</b> {content3BoldText[1]}
              </span>
            </p>
          ) : (
            <p>
              <span className={Styles.content}>{content3}</span>
            </p>
          )}
        </div>
        <Link to={"https://forms.gle/EFU47rgSkHYgXZyH6"} target="_blank">
          <div className={Styles.card_btn}>
            Enquire now <i className="fa-solid fa-arrow-right"> </i>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default OurServiceCard;
