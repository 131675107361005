import React, { useState, useEffect } from 'react';
import styles from './OtpInput.module.css';
import axios from 'axios';

import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const OtpInput = ({ onOtpComplete, onOtpVerified, mobileNumber }) => {
  const [otp, setOtp] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });

  const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const verifyOtp = async () => {
    console.log(mobileNumber, `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}`);
    try {
      const res = await axios.post(`${baseApiUrl}/api/v1/auth/verification/verify`, {
        mobileNumber,
        verificationCode: `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}`,
        useCase: 'Signup'
      }, {
        withCredentials: true
      });

      console.log("OTP verification successful:", res);
      onOtpVerified(true);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("Error verifying OTP:", error);
      onOtpVerified(false);
    }
  };

  useEffect(() => {
    const allFilled = Object.values(otp).every(value => value !== "");
    onOtpComplete(allFilled);

    if (allFilled) {
      verifyOtp();
    }
  }, [otp, onOtpComplete]);

  const handleChange = (name, event) => {
    const value = event.target.value;
    if (value.length <= 1) {
      setOtp({ ...otp, [name]: value });
    }
  };

  const inputFocus = (elmnt) => {
    if (elmnt.key === "Backspace") {
      const prev = elmnt.target.tabIndex - 2;
      if (prev > -1) {
        elmnt.target.form.elements[prev].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <form>
      <ToastContainer />
      <div className={styles.otpContainer}>
        {[1, 2, 3, 4].map((i) => (
          <input
            key={i}
            name={`otp${i}`}
            type="text"
            autoComplete="off"
            className={styles.otpInput}
            value={otp[`otp${i}`]}
            onChange={(e) => handleChange(`otp${i}`, e)}
            tabIndex={i}
            maxLength="1"
            onKeyUp={inputFocus}
          />
        ))}
      </div>
    </form>
  );
};

export default OtpInput;