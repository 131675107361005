import { Link } from "react-router-dom";
import styles from "./GetStarted.module.css";

const GetStarted = ({
  heading,
  para,
  para2,
  image,
  smallImage,
  bgColor,
  buttonBgColor,
  buttonColor,
  smallImageTop,
  smallImageMarginBottom,
  isMain = false,
}) => {
  let mt = isMain ? "4rem" : "";

  let styleObj = isMain ? { position: "relative", right: "-7%" } : {};

  return (
    <div className={styles.cont} style={{ background: bgColor, marginTop: mt }}>
      <section className={styles.cont__inside}>
        <section className={styles.cont__inside__left}>
          <section className={styles.cont__inside__left__inside}>
            <h2>{heading}</h2>
            <p>{para}</p>
            {para2 && <p>{para2}</p>}
            <Link
              className={styles.link}
              to={"https://forms.gle/EFU47rgSkHYgXZyH6"}
              target="_blank"
            >
              <button style={{ background: buttonBgColor, color: buttonColor }}>
                Enquire Now
              </button>
            </Link>
          </section>
        </section>
        <section className={styles.cont__inside__right}>
          <img src={image} className={styles.largeImage} style={styleObj} />
          <img
            src={smallImage}
            style={{
              top: smallImageTop ? smallImageTop : null,
              marginBottom: smallImageMarginBottom
                ? smallImageMarginBottom
                : null,
            }}
            className={styles.smallImage}
          />
        </section>
      </section>
    </div>
  );
};

export default GetStarted;
