import Styles from "./AboutUs.module.css";

const AboutUs = ({ data }) => {
  return (
    <div className={Styles.aboutUs_page}>
      {data?.map((section, index) =>
        index % 2 === 0 ? (
          <div key={index} className={`${Styles.section}`}>
            <div className={`${Styles.image_section}`}>
              <img
                src={section.image}
                style={{
                  marginTop: section.marginTop ? section.marginTop : "",
                }}
                className={Styles.section_img}
                alt="imagedata"
              />
            </div>
            <div className={Styles.section_data_list}>
              {section?.info.map((info, headingIndex) => (
                <div className={` ${Styles.section_data}`} key={headingIndex}>
                  <h3
                    style={{ color: section.headingColor }}
                    className={Styles.heading}
                  >
                    {info.heading}
                  </h3>
                  {info.content?.map((content) => (
                    <p className={Styles.content}>
                      <span>{info.special_symbol && info.special_symbol} </span>
                      {content}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div key={index} className={`${Styles.section} ${Styles.section2}`}>
            <div className={`${Styles.section_data_list} `}>
              {section?.info.map((info, headingIndex) => (
                <div className={` ${Styles.section_data}`} key={headingIndex}>
                  <h3
                    style={{ color: section.headingColor }}
                    className={Styles.heading}
                  >
                    {info.heading}
                  </h3>
                  {info.content?.map((content) => (
                    <p className={Styles.content}>
                      {" "}
                      <span style={{ color: "green" }}>
                        {info.special_symbol && info.special_symbol}{" "}
                      </span>
                      {content}
                    </p>
                  ))}
                </div>
              ))}
            </div>
            <div className={`${Styles.image_section}`}>
              <img
                src={section.image}
                className={Styles.section_img}
                alt="imagedata"
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AboutUs;
