import React, { useState } from "react";
import Styles from "./Header.module.css";
import { Link } from "react-router-dom";

// import '/Assets/MainPage/Images/Connect logo(Without Tagline) intro navbar.svg'

const Header = ({
  primaryColor,
  secondaryColor,
  backgroundColor,
  activeSection,
  handleNavClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log(activeSection);

  return (
    <>
      <div className={Styles.navbar} style={{ background: backgroundColor }}>
        <Link to="/">
          <img
            className={Styles.logo}
            src={
              "/Assets/MainPage/Images/Connect logo(Without Tagline) intro navbar.svg"
            }
            alt="Syrup being poured over div stack of pancakes"
          />
        </Link>
        <ul className={Styles.links}>
          <li>
            <div
              onClick={() => handleNavClick("section2")}
              className={
                activeSection === "section2"
                  ? Styles.active_section
                  : Styles.header_option
              }
            >
              About us
            </div>
          </li>
          <li>
            <div
              onClick={() => handleNavClick("section3")}
              className={
                activeSection === "section3"
                  ? Styles.active_section
                  : Styles.header_option
              }
            >
              Our Services
            </div>
          </li>
          <li>
            <div
              onClick={() => handleNavClick("section4")}
              className={
                activeSection === "section4"
                  ? Styles.active_section
                  : Styles.header_option
              }
            >
              Why Choose us
            </div>
          </li>
          <li>
            <div
              onClick={() => handleNavClick("section5")}
              className={
                activeSection === "section5"
                  ? Styles.active_section
                  : Styles.header_option
              }
            >
              About Opportunities
            </div>
          </li>
          <li>
            <div
              onClick={() => handleNavClick("section6")}
              className={
                activeSection === "section6"
                  ? Styles.active_section
                  : Styles.header_option
              }
            >
              Our Process
            </div>
          </li>
          <li>
            <div
              onClick={() => handleNavClick("section7")}
              className={
                activeSection === "section7"
                  ? Styles.active_section
                  : Styles.header_option
              }
            >
              FAQs
            </div>
          </li>
        </ul>
        <ul className={Styles.links}></ul>
        <div
          className={Styles.toggle_btn}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <i className={`fa-solid ${!isOpen ? "fa-bars" : "fa-xmark"}`} />
        </div>
      </div>
      <div className={`${Styles.dropdown_menu} ${isOpen ? Styles.open : ""}`}>
        <li>
          <div
            onClick={() => handleNavClick("section2")}
            className={
              activeSection === "section2"
                ? Styles.active_section
                : Styles.header_option
            }
          >
            About us
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavClick("section3")}
            className={
              activeSection === "section3"
                ? Styles.active_section
                : Styles.header_option
            }
          >
            Our Services
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavClick("section4")}
            className={
              activeSection === "section4"
                ? Styles.active_section
                : Styles.header_option
            }
          >
            Why Choose us
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavClick("section5")}
            className={
              activeSection === "section5"
                ? Styles.active_section
                : Styles.header_option
            }
          >
            About Opportunities
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavClick("section6")}
            className={
              activeSection === "section6"
                ? Styles.active_section
                : Styles.header_option
            }
          >
            Our Process
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavClick("section7")}
            className={
              activeSection === "section7"
                ? Styles.active_section
                : Styles.header_option
            }
          >
            FAQs
          </div>
        </li>
      </div>
    </>
  );
};

export default Header;
