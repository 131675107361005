import { Link } from "react-router-dom";
import MoreDetailBtn from "../../../../components/moreDetailButton/MoreDetailButton";
import styles from "./SectorCard.module.css";
import { useEffect } from "react";
// import '../../../../images/assets/Frame construction.webp'
const SectorCard = ({
  id,
  title,
  tagline,
  content,
  image,
  hoverClass,
  moreDetail,
}) => {

  // let t = '';
  // if (id === "1") t = 'construction';
  // if (id === "3") t = 'event';
  // if (id === "4") t = 'entertainment';
  // if (id === "5") t = 'homeservice';
  // if (id === "6") t = 'petcare';

  return (
    <div className={styles.container}>
      <div className={styles.childContainer}>
        <div className={styles.headingShadow}>{title}</div>
        <div className={`${styles.containerContent} ${styles[hoverClass]}`}>
          <img
            src={`${image}`}
            alt=""
            className={styles.contentImageLeft}
            style={{ display: id % 2 !== 0 ? "block" : "none" }}
          />
          <div className={styles.contentText}>
            <div>
              <p className={styles.contentTextHeader}>{tagline}</p>
            </div>

            <p className={styles.contentTextDetail}>{content}</p>
            <div className={styles.contentTextFooter}>
              <MoreDetailBtn
                text="More Details"
                backgroundcolor={moreDetail.backgroundcolor}
                color={moreDetail.color}
                to={`/${title === "Home Services" ? "homeService" : title}`}
              />

              <div
                className={styles.partner}
                style={{
                  border: `1px solid ${moreDetail.backgroundcolor}`,
                }}
              >
                <Link
                  className={styles.link}
                  to={`/auth/signup-step-1`}
                >
                  <i className="fa-solid fa-handshake"></i>&nbsp; Partner with
                  us
                </Link>
              </div>
            </div>
          </div>
          <img
            src={`${image}`}
            alt=""
            className={styles.contentImageRight}
            style={{ display: id % 2 === 0 ? "block" : "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default SectorCard;
