import React, { useEffect, useState } from 'react';
import styles from './Main.module.css';
import Login from '../Login/Login';
import ForgotPassword from '../../ForgotPassword/ForgotPassword/ForgotPassword';
import Modal from '../../../utils/modal/Modal';
// import Modal from '../../utils/modal/Modal';
// import axios from "axios";

const Main = () => {

    const [forgetPaasswordActive, setForgetPasswordActive] = useState(false);

    const [mobileNumber, setMobileNumber] = useState(null);
    const [password, setPassword] = useState(null);
    const [sectorId, setSectorId] = useState(null);
    const [imageUrl, setImageUrl] = useState("/Assets/Authentication/main.svg");

    const closeModal = () => {
        setForgetPasswordActive(false);
    }

    // api request

    // const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;
    // const fetchSector = async () => {
    //     try {
    //         const res = await axios.post(`${baseApiUrl}/api/v1/sectors/me`, { sectorName: "construction" }, { withCredentials: true });
    //         console.log(res);
    //         setSectorId(res.data.data.sector.id);
    //     } catch (error) {
    //         console.log("cannnot fetch sector :", error);
    //     }
    // };

    // useEffect(() => {
    //     fetchSector();
    // }, []);



    return (
        <div className={styles.cont}>
            <Modal show={forgetPaasswordActive} onClose={closeModal}>
                <ForgotPassword sectorId={sectorId} />
            </Modal>
            <section className={styles.cont__left}>
                <img src={imageUrl} alt="Login..." />
            </section>
            <section className={styles.cont__right}>
                <Login setForgetPasswordActive={setForgetPasswordActive} />
            </section>
        </div>
    );
}

export default Main;