import JoinCommunityButtons from "../../../Components/JoinCommunityButtons/JoinCommunityButtons";
import styles from "../Styles/LandingTop.module.css";

const EventLandingTop = ({
  heading,
  headingColor,
  tag,
  tagColor,
  tagBgColor,
  para,
  bgColor,
  image,
  joinBtnC1,
  joinBtnC2,
  bgImage,
}) => {
  return (
    <div
      className={styles.cont}
      style={{
        background: `${bgColor},${bgImage}`,
        // backgroundImage: bgImage,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <section className={styles.cont__left}>
        <section className={styles.cont__left__inside}>
          <span style={{ color: tagColor, background: tagBgColor }}>{tag}</span>
          <h1 style={{color:headingColor}}>{heading}</h1>
          <h3 className={styles.subHeading}>Streamline Your Event Planning with Connect</h3>
          <p className="text-muted">{para}</p>
          <JoinCommunityButtons
            backgroundColor1={joinBtnC1}
            backgroundColor2={joinBtnC2}
          />
        </section>
      </section>
      <section className={styles.cont__right}>
        <img src={image} alt="imagedata" />
      </section>
    </div>
  );
};

export default EventLandingTop;
