import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = ({
  iconsBgColor = "yellow",
  iconsColor,
  contactColor = "yellow",
  handleNavClick,
  isMain = false
}) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className={styles.top}>
        <section className={styles.top__inside}>
          <section style={{ width: isMain ? '50%' : '40%' }} className={styles.top__inside__first}>
            <img src="/Assets/Construction/Images/footer_logo.svg" alt="..." />
            <p>
              At Connect, we are dedicated to bridging the gap between consumers
              and top-tier service providers across all sectors.
            </p>
            <span>
              <Link
                to={
                  "https://www.instagram.com/connect_solutionss/?igsh=MWdkbDdjdXZxeGFiaQ%3D%3D"
                }
                target="_blank"
              >
                <i
                  className="fa-brands fa-instagram"
                  style={{ color: iconsColor, backgroundColor: iconsBgColor }}
                />
              </Link>
              <Link
                to={
                  "https://x.com/connect_solutns?s=21&t=xeqBgjMxSin4lFC3XGTAKg"
                }
                target="_blank"
              >
                <i
                  className="fa-brands fa-twitter"
                  style={{ color: iconsColor, backgroundColor: iconsBgColor }}
                />
              </Link>
              <Link
                to={"https://www.linkedin.com/company/connectsolutionss/"}
                target="_blank"
              >
                <i
                  className="fa-brands fa-linkedin"
                  style={{ color: iconsColor, backgroundColor: iconsBgColor }}
                />
              </Link>
              <Link
                to={
                  "https://www.facebook.com/people/Connect-Solutionss/61560584010523/?mibextid=LQQJ4d"
                }
                target="_blank"
              >
                <i
                  className="fa-brands fa-facebook"
                  style={{ color: iconsColor, backgroundColor: iconsBgColor }}
                />
              </Link>
              <Link to={"https://wa.me/message/XYVZSYLAI3XUB1"} target="_blank">
                <i
                  className="fa-brands fa-whatsapp"
                  style={{ color: iconsColor, backgroundColor: iconsBgColor }}
                />
              </Link>
              {/* <Link>
                                <i className="fa-brands fa-discord" style={{ color: iconsColor, backgroundColor: iconsBgColor }} />
                            </Link> */}
            </span>
          </section>
          {!isMain && <section className={styles.top__inside__second}>
            <h3>Company</h3>
            <a href="#" onClick={() => handleNavClick("section2")}>
              About us
            </a>
            <a href="#" onClick={() => handleNavClick("section3")}>
              Our Services
            </a>
            <a href="#" onClick={() => handleNavClick("section6")}>
              Our Process
            </a>
            <a href="#" onClick={() => handleNavClick("section5")}>
              Opportunities
            </a>
            <a href="#" onClick={() => handleNavClick("section7")}>
              FAQs
            </a>
          </section>}
          {/* <section className={styles.top__inside__third}>
            <h3>Support</h3>
            <a href="#">Help Center</a>
            <a href="#">Feedback</a>
          </section> */}
          <section className={styles.top__inside__fourth}>
            <h3>Other Sectors</h3>
            <Link to={"/construction"}>
              <span onClick={scrollToTop}> Construction</span>
            </Link>
            <Link to="/petcare">
              <span onClick={scrollToTop}>Pet Care</span>
            </Link>
            <Link to="/healthcare">
              <span onClick={scrollToTop}>Health Care</span>
            </Link>
            <Link to="/homeService">
              <span onClick={scrollToTop}>Home Services</span>
            </Link>
            <Link to="/event">
              <span onClick={scrollToTop}>Event</span>
            </Link>
            <Link to="/entertainment">
              <span onClick={scrollToTop}>Entertainment</span>
            </Link>
          </section>
          <section className={styles.top__inside__fifth}>
            <h3>Contact Us</h3>
            <section className={styles.top__inside__fifth__item}>
              <section>
                <i
                  className="fa-solid fa-phone"
                  style={{ color: contactColor }}
                />
              </section>
              <span>
                <text>(+91) 7738388034</text>
              </span>
            </section>
            <section className={styles.top__inside__fifth__item}>
              <section>
                <i
                  className="fa-solid fa-envelope"
                  style={{ color: contactColor }}
                />
              </section>
              <span>
                <text>Support@connectsolutionss.in</text>
              </span>
            </section>
            <section className={styles.top__inside__fifth__item}>
              <section>
                <i
                  className="fa-solid fa-location-dot"
                  style={{ color: contactColor }}
                />
              </section>
              <span>
                <text>
                  Jethabhai Building DR Atmaram merchant Road, Bhuleshwar Mumbai
                  Mumbai City MH 400002 India
                </text>
              </span>
            </section>
          </section>
        </section>
      </div>
      <section className={styles.divider}></section>
      <section className={styles.bottom}>
        <section className={styles.bottom__inside}>
          <span className={styles.bottom__left}>
            Copyright by V delever solutionss pvt ltd
          </span>
          <span className={styles.bottom__right}>
            <Link to="/privacy/privacyPolicy" target='_blank'><span className={styles.bottom__right__first}>Privacy Policy</span> </Link>
            <Link to="/privacy/termOfUse" target='_blank'> <span className={styles.bottom__right__second}>Terms of Use</span> </Link>
            {/* <span className={styles.bottom__right__third}>Legal</span> */}
          </span>
        </section>
      </section>
    </>
  );
};

export default Footer;
