import styles from "./FAQsList.module.css";
import { useState } from "react";

const FAQsList = ({ backgroundColor, color, data }) => {
  const [idValue, setId] = useState(-1);

  const onQuestionClick = (e) => {
    const value = Number(e.currentTarget.id);
    console.log(value, "index");

    if (value === idValue) {
      setId(-1);
      console.log("close");
    } else {
      setId(Number(value));
      console.log(" open");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className={styles.accordion}>
            {data.map((pair, index) => {
              return (
                <div
                  key={index}
                  id={index}
                  className={` ${styles.accordion_content} ${
                    idValue === index ? styles.active : ""
                  }`}
                >
                  <div
                    onClick={onQuestionClick}
                    id={index}
                    className={styles.question}
                  >
                    <span
                      style={{ backgroundColor: backgroundColor, color: color }}
                      className={styles.serialNumber}
                    >
                      {index + 1}
                    </span>{" "}
                    <p className={styles.questionContent}>{pair.question}</p>
                  </div>
                  <div id={index} className={styles.answer}>
                    {pair.answer}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQsList;
