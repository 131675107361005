export const ConstructionAboutUsData = [
  {
    image: "/Assets/Construction/Images/img2.webp",
    headingColor: "#00183D",
    info: [
      {
        heading: "About us",
        content: [
          " Cementing Dreams, Crafting Futures: At Connect, our success is built on the dedication of every member of our team, from skilled labourers to seasoned professionals. Together, we transcend the ordinary, sculpting visions into reality with innovation and unwavering dedication. From towering skyscrapers to cosy abodes, our commitment to excellence knows no bounds. Join us as we assemble a diverse team, where every voice and skillset contributes to building the extraordinary.",
        ],
      },
      {
        heading: "Our Vision",
        content: [
          " To revolutionize the construction industry by becoming the most trusted and comprehensive provider of skilled labor and professional services, ensuring quality transparency, and efficiency in every project.",
        ],
      },
    ],
  },
  {
    image: "/Assets/Construction/Images/img3.webp",
    headingColor: "#00183D",
    info: [
      {
        heading: "Our Mission",
        content: [
          " Our Mission is to deliver exceptional construction services by connecting builders individuals, and companies with skilled labor and professionals through our innovative platform. We strive to provide quality, reliability, and transparency, making us the go-to solution for all construction needs.",
        ],
      },
    ],
  },
];

export const EventAboutUsData = [
  {
    image: "/Assets/Events/Images/img2.webp",
    headingColor: "#3E1B03",
    info: [
      {
        heading: "About us",
        content: [
          "Connect is your event planning guide, Where top professionals and clients collide. Streamlining the process with effortless grace, We bring excellence to every space.",
          "Our mission is clear, our vision is grand, To be the best platform across the land. With diverse services and reliability,Connect ensures your event’s tranquility.",
          "From grand galas to intimate affairs, We handle it all with utmost care.For seamless planning and perfect execution, Connect is your ultimate event solution.",
        ],
      },
    ],
  },
  {
    image: "/Assets/Events/Images/img3.webp",
    headingColor: "#3E1B03",
    info: [
      {
        heading: "Our Core Values",
        special_symbol: "✓",
        content: [
          "Quality: We strive to feature only the best and most reliable vendors on our platform.",
          "Convenience: Our user-friendly app makes event planning easy and stress-free.",
          "Customer Satisfaction: We prioritize our clients' needs and work tirelessly to exceed their expectations.",
          "Innovation: We continuously seek to improve and expand our services to meet the evolving demands of the events industry.",
        ],
      },
    ],
  },
];

export const HealthCareAboutUsData = [
  {
    image: "/Assets/Healthcare/Images/img2.webp",
    headingColor: "#05053D",
    info: [
      {
        heading: "About us",
        content: [
          "At Connect, we prioritize your health and well-being above all else. Our healthcare services are designed to provide you with the highest standard of care, whether you're seeking Home care services, diagnostic procedures, Nursing care, treatment options, or Telemedicine. Our team of experienced healthcare professionals is dedicated to ensuring that you receive personalized care tailored to your specific needs. With a focus on innovation and compassion, we strive to enhance your overall health and quality of life. Trust us to be your partner in health and wellness.",
        ],
      },
      {
        heading: "Our Vision",
        content: [
          " To transform healthcare by making high-quality services accessible for everyone, at every stage of life. Together, let's create a healthier, happier world!",
        ],
      },
    ],
  },
  {
    image: "/Assets/Healthcare/Images/img3.webp",
    headingColor: "#05053D",
    info: [
      {
        heading: "Our Mission",
        content: [
          " We are committed to advancing healthcare through technology and expertise, ensuring that individuals have access to the best possible care. Connect aims to give access to a person to take control of their good health and good being. We are dedicated to enhancing the lifestyle of millions of people living around the sphere.",
        ],
      },
    ],
  },
];

export const PetCareAboutUsData = [
  {
    image: "/Assets/Petcare/Images/img3.webp",
    headingColor: "#27001B",
    info: [
      {
        heading: "About us",
        content: [
          "At Connect, the pet care industry is a thriving and diverse sector encompassing various services, from grooming, pet training  and pet adopting. We've established a dedicated platform for pet owners, caregivers, and enthusiasts to come together, collaborate, and celebrate their love for animals.",
          " Whether you're a pet parent, groomer, Dog walker, or animal behaviorist, you'll find a supportive community that embraces expertise and cultivates opportunities to enhance the well-being of our furry friends.",
        ],
      },
      {
        heading: "Our Vision",
        content: [" To be one-stop solution for all your pet care needs"],
      },
      {
        heading: "Our Mission",
        content: [
          "Our mission is to enhance the lives of pets and their owners by providing compassionate, high-quality pet care services tailored to their unique needs, ensuring happier, healthier, and more fulfilling relationships.",
        ],
      },
    ],
  },
];
