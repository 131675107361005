import styles from "./LandingPage.module.css";

import gif from "../../../Assets/Icons/Assets/Scroll down Animation - Connect.json";

import Lottie from "lottie-react";

function LandingPage() {
  return (
    <div className={styles.coverPage}>
      <div className={styles.coverPageContent}>
        <div className={styles.logoDiv}>
          <img
            className={styles.logo}
            src="/Assets/MainPage/Images/Connect logo LB 1.svg"
            alt=""
          />
        </div>

        <div className={styles.coverPageTextDiv}>
          <Lottie animationData={gif} className={styles.gif} />
          <p className={styles.scrollDown}>Scroll down</p>
          <p className={`${styles.coverPageText} ${styles.coverPageText1}`}>
            To Discover a world of opportunities right at your fingertips. From
            construction to healthcare, pet care to home services, and more,
            Connect makes it easy to find expert solutions with just a click.
            Start your journey to excellence here.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
