import { Link } from "react-router-dom";
import Styles from "./JoinCommunityButtons.module.css";

const JoinCommunityButtons = ({ backgroundColor1, backgroundColor2 }) => {
  return (
    <div className={Styles.buttonSection}>
      {/* <button className={Styles.discord}
        style={{ backgroundColor: backgroundColor1, color: backgroundColor1 === '#FED462' ? 'black' : 'white' }}>
        <i className="fa-brands fa-discord"></i>&nbsp; Join our Discord
      </button> */}
      <Link to={`/auth/signup-step-1`} target="_blank">
        <button
          className={Styles.partner}
          style={{
            backgroundColor: backgroundColor2,
            border: `1px solid ${backgroundColor1}`,
          }}
        >
          <i className="fa-solid fa-handshake"></i>&nbsp; Partner with us
        </button>
      </Link>
    </div>
  );
};

export default JoinCommunityButtons;
