import React, { useState, useEffect } from 'react';
import styles from './OtpInput.module.css';

const OtpInput = ({ onOtpComplete, finalOtp }) => {
  const [otp, setOtp] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });

  useEffect(() => {
    const allFilled = Object.values(otp).every(value => value !== "");
    onOtpComplete(allFilled);

    if (allFilled) {
      finalOtp(`${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}`)
    }
  }, [otp, onOtpComplete]);

  const handleChange = (name, event) => {
    const value = event.target.value;
    if (value.length <= 1) {
      setOtp({ ...otp, [name]: value });
    }
  };


  const inputFocus = (elmnt) => {
    if (elmnt.key === "Backspace") {
      const prev = elmnt.target.tabIndex - 2;
      if (prev > -1) {
        elmnt.target.form.elements[prev].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <form>
      <div className={styles.otpContainer}>
        {[1, 2, 3, 4].map((i) => (
          <input
            key={i}
            name={`otp${i}`}
            type="text"
            autoComplete="off"
            className={styles.otpInput}
            value={otp[`otp${i}`]}
            onChange={(e) => handleChange(`otp${i}`, e)}
            tabIndex={i}
            maxLength="1"
            onKeyUp={inputFocus}
          />
        ))}
      </div>
    </form>
  );
};

export default OtpInput;