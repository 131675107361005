import { Link } from 'react-router-dom';
import styles from './Completed.module.css';
import { GiInfo } from "react-icons/gi";

const Completed = ({ setIsSignupCompleted }) => {

    return (
        <div className={styles.cont}>
            <img src="/Assets/Authentication/completed.png" className={styles.topLogo} />
            <section className={styles.topHeading}>
                <text className={styles.topHeading__first}>
                    Congratulations!
                </text>
                <text className={styles.topHeading__second}>
                    Your account has been successfully created.
                    Thank you for joining us!
                </text>
            </section>
            <section className={styles.info}>
                <GiInfo style={{ minHeight: "20px", minWidth: "20px" }} color='#1A73E8' />
                <text>
                    We are thrilled to announce that our new app is on the way! This innovative platform is designed to revolutionize the way you connect.
                </text>
            </section>

            <Link className={styles.bottomButton} to={"/auth/login"} onClick={() => { setIsSignupCompleted(false) }}>
                {/* <button > */}
                Login
                {/* </button> */}
            </Link>
        </div>
    );
}

export default Completed;