import { ICONS } from "../../../../../Assets/Icons/index";
import styles from "./TopSection.module.css";
const TopSection = ({ getStartedBtn }) => {
  return (
    <div>
      <div
        className={styles.topSection}
        style={{
          backgroundImage: `url("/Assets/MainPage/IntroPageImg/BG%20IMAGES.webp")`,
        }}
      >
        <div className={styles.topSectionContent}>
          <div className={styles.topLogo}>{ICONS.connectLogoWhite}</div>
          <p className={styles.introTagline}>
            Discover Your Next Adventure: Connect Across Industries!
          </p>
          <p className={styles.introText}>
            Welcome to Connect! Whether you're into building, pet care, home
            services, events, entertainment, or healthcare, we've got something
            exciting for you. Join our community of passionate individuals and
            explore endless opportunities. From shaping skylines to nurturing
            pets, creating memorable events, and improving lives, your next
            adventure starts here. Sign up now and let's connect industries,
            connect lives!
          </p>
          <div className={styles.getStartedBtn} onClick={() => getStartedBtn()}>
            Get started
          </div>
        </div>

        <div className={styles.socialmediaLinks}>
          <a
            href=" https://wa.me/message/XYVZSYLAI3XUB1"
            target="_blank"
            className={styles.topSocialmediaIcon}
          >
            {ICONS.whatsappIcon}
          </a>
          {/* <div className={styles.topSocialmediaIcon}>{ICONS.discordIcon}</div> */}

          <a
            href=" https://www.facebook.com/profile.php?id=61560584010523&mibextid=LQQJ4d"
            target="_blank"
            className={styles.topSocialmediaIcon}
          >
            {ICONS.facebookIcon}
          </a>

          <a
            href=" https://www.linkedin.com/company/connectsolutionss/"
            target="_blank"
            className={styles.topSocialmediaIcon}
          >
            {ICONS.linkedInIcon}
          </a>

          <a
            href="https://x.com/connect_solutns?s=21&t=xeqBgjMxSin4lFC3XGTAKg "
            target="_blank"
            className={styles.topSocialmediaIcon}
          >
            {ICONS.twitterIcon}
          </a>
          <a
            href="https://www.instagram.com/connect_solutionss?igsh=bTZ1YXNpc2V6c2Vh&utm_source=qr "
            target="_blank"
            className={styles.topSocialmediaIcon}
          >
            {ICONS.instaIcon}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
