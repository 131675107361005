import React, { useState, useEffect, useRef } from "react";
import { scroller } from "react-scroll";

import Styles from "./Entertainment.module.css";
import CommonCss from "../../Styles/CommonStyle.module.css";
import Header from "../../../Sections/Header/Header";
import WhyChooseCard from "../../../Components/WhyChooseCard/WhyChooseCard";
import OurServiceCard from "../../../Components/OurServiceCard/OurServiceCard";
import EntertainmentLandingTop from "../../../Sections/LandingTop/entertainment/EntertainmentLandingTop";
import Footer from "../../../Sections/Footer/Footer";
import GetStarted from "../../../Sections/GetStarted/GetStarted";
import FactDisplaySection from "../../../Components/FactDisplaySection/FactDisplaySection";
import AboutUs from "../../../Sections/AboutUs/AboutUs";
import OurProcess from "../../../Sections/OurProcess/OurProcess";
import FAQsList from "../../../Sections/FAQs/FAQsList";
import { EntertainmentFAQ } from "../../../Sections/FAQs/FAQsListData";
import AboutOpportunities from "../../../Sections/AboutOpportunities/AboutOpportunities";
import { AboutOpportunitiesEntertainment } from "../../../Sections/AboutOpportunities/AboutOpportunitiesData";
import { EntertainmentOurProcessData } from "../../../Sections/OurProcess/OurProcessData";

const EntertainmentLanding = () => {
  const [activeSection, setActiveSection] = useState("section1");

  // console.log(activeSection, "activeSection");

  const sectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      const domNode = ref.current;
      // console.log(domNode, " ref.current;");
      if (domNode) {
        observer.observe(domNode);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        const domNode = ref.current;
        if (domNode) {
          observer.unobserve(domNode);
        }
      });
    };
  }, [sectionRefs]);

  const handleNavClick = (section) => {
    scroller.scrollTo(section, {
      duration: 100,
      delay: 0,
      smooth: "easeInOutQuad",
      offset: -50,
    });
  };

  return (
    <>
      {/* --------------------------------------Header----------------------------------------------- */}

      <Header
        handleNavClick={handleNavClick}
        activeSection={activeSection}
        primaryColor={
          "linear-gradient(0deg, #FF8A00 0%, #FF8A00 100%), #281508"
        }
        secondaryColor={"#fff"}
        backgroundColor={
          "linear-gradient(0deg, rgba(114, 35, 145, 0.30) 0%, rgba(114, 35, 145, 0.30) 100%), #F8F9FF"
        }
      />

      {/* -------------------------------------Landing Top-------------------------------------- */}

      <section id="section1" ref={sectionRefs.section1}  style={{ overflow: "hidden" }}>
        <EntertainmentLandingTop
          bgColor={
            "linear-gradient(180deg, rgba(114, 35, 145, 0.30) 0%, rgba(114, 35, 145, 0.00) 100%)"
          }
          tagColor={"#722391"}
          tagBgColor={"rgba(120, 41, 150, 0.10)"}
          heading={`CONNECT: Your Gateway to the Entertainment World`}
          headingColor="#21113D"
          para={`Join our vibrant community of entertainment professionals and
              enthusiasts. Network, collaborate, and celebrate the arts in
              music, film, theatre, and more.`}
          tag={`#1 solution for entertainment needs`}
          joinBtnC1={"#FF8A00"}
          joinBtnC2={"#F1DFE0"}
          image={"/Assets/heroImages/entertainmentHero.webp"}
          bgImage={`url('/Assets/BackgroundImages/EntertainmentBackgroundImage.svg')`}
        />
      </section>
      
      {/* --------------------------------Fact display bar --------------------------------------------- */}

      <section>
        <FactDisplaySection
          color={"#fff"}
          backGroundColor={"#722391"}
          otherColor={"#FF6B00"}
          lineColor={"#FCFCFC"}
          fact1={{ figure: 20, title: "Active Directors" }}
          fact2={{ figure: 50, title: "Other service providers" }}
          fact3={{ figure: 80, title: "Assistant Staff" }}
          fact4={{ figure: 50, title: "Actor Staff" }}
        />
      </section>

      {/* ---------------------------------------------About Us -------------------------------------------------- */}

      <section
        className={CommonCss.section_top1}
        id="section2"
        ref={sectionRefs.section2}
      >
        <AboutUs
          data={[
            {
              image: "/Assets/Entertainment/Images/entertainment_aboutus.png",
              marginTop:'2rem',
              info: [
                {
                  heading: "About us",
                  content: [
                    " The entertainment sector is a thriving and ever-evolving industry that encompasses various creative fields, including music, film, theatre, dance, comedy, and live events.",
                    "At CONNECT, we have established a specialised platform for professionals in the entertainment industry, event organisers, and enthusiasts to network, collaborate, and express their love for the arts. Whether you are an actor, musician, filmmaker, event planner, or performer, our community is dedicated to celebrating talent and nurturing opportunities.",
                  ],
                },
              ],
            },
          ]}
        />
      </section>

      {/* -----------------------------------------Our Services--------------------------------------------------- */}

      <section
        className={Styles.section_top2}
        id="section3"
        ref={sectionRefs.section3}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Services
          </h2>
          <p className={CommonCss.section_subHeading}>
            We offer talented actors, visionary directors, innovative writers,
            skilled cameramen, renowned singers and everyone from every sector
            of the industry. From concept to production, our comprehensive
            entertainment services ensure captivating and high-quality results.
          </p>
          <div className={CommonCss.containerSection}>
            <OurServiceCard
              imageSource={"/Assets/Entertainment/Images/maker.webp"}
              heading={"Makers"}
              textAlign={"left"}
              content={
                "Visionaries who bring your project to life through directing, producing, writing, composing, and much more. This includes Directors, Writers, Producers, Cinematographers, Editors, Production Designers, Graphic Designers, Art Directors, Music Composers/Directors, Costume Designers, Line Producers, Makeup Artists, Hair Stylists, Choreographers, and Performers like Actors, Influencers, Singers, Musicians, and Dubbing Artists."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Entertainment/Images/performer.webp"}
              heading={"Performers"}
              content="Talented individuals who captivate audiences with their talent. Our performers include Actors, Actresses, Models, Voice Actors/Dubbing Artists, Stunt Performers, Supporting Actors, Musicians, Look-Alikes, Dancers, and Singers.
"
            />
            <OurServiceCard
              imageSource={"/Assets/Entertainment/Images/vendor.webp"}
              heading={"Vendors"}
              content="Reliable service providers for essential services and equipment. This covers Equipment Rentals (cameras, drones, lights), Props Suppliers, Costume Supplies (Tailors), Caterers, Transportation, Security, Insurance, Production Suppliers, Recording Studios (Audio and Video), Stays/Locations, and Catering Services.
"
            />
            <OurServiceCard
              imageSource={"/Assets/Entertainment/Images/staff.webp"}
              heading={"Staffs"}
              content={
                "Dedicated professionals providing essential support and services. Administrative Staff, Production Assistants, Technical Crew, Event Coordinators."
              }
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------Why to choose us--------------------------------------------- */}

      <section
        className={CommonCss.section_top3}
        id="section4"
        ref={sectionRefs.section4}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Why Choose us
          </h2>
          <h3 className={CommonCss.section_subHeading2}>
            Welcome to Connect: Your Gateway to Success in the Entertainment
            Industry
          </h3>
          <p className={CommonCss.section_subHeading}>
            At Connect, we understand the unique needs and challenges of
            professionals in the entertainment sector. Whether you're an actor,
            musician, filmmaker, event planner, or any other entertainment
            professional, our platform is designed to empower you to thrive in
            your career. Here's why you should choose Connect:
          </p>
          <div className={CommonCss.containerSection}>
            <WhyChooseCard
              imageSource={"/Assets/Entertainment/Images/img3.png"}
              heading={"Comprehensive Networking"}
              content={
                "Expand Your Network: Connect with a diverse community of entertainment professionals, including actors, musicians, directors, producers, and more. Build valuable connections that can lead to collaborations, partnerships, and career opportunities."
              }
              content2={
                "Exclusive Industry Events:  Access exclusive networking events, workshops, and seminars hosted by industry experts and influencers. Stayupdated on the latest trends and developments in the entertainment industry."
              }
              width={"350px"}
            />
            <WhyChooseCard
              imageSource={"/Assets/Entertainment/Images/img4.png"}
              heading={"Showcase Your Talent"}
              content={
                "Personalized Profiles: Create a stunning profile that showcases your talent, experience, and portfolio. Upload photos, videos, and audio samples to highlight your work and attract potential collaborators and clients."
              }
              content2={
                "Promote Your Projects: Showcase your latest projects, performances, or productions to a global audience. Gain visibility and recognition for your work within the entertainment community."
              }
              width={"350px"}
            />

            <WhyChooseCard
              imageSource={"/Assets/Entertainment/Images/img5.png"}
              heading={"Discover Opportunities"}
              content={
                "Audition Calls & Gig Listings: Explore audition calls and gig listings tailored to your skills and interests. Apply for exciting opportunities in film, television, theatre, music, and more."
              }
              content2={
                "Job Postings: Browse job postings for full-time, part-time, and freelance positions in the entertainment industry. Find your next career opportunity with ease."
              }
              width={"350px"}
            />
            <WhyChooseCard
              imageSource={"/Assets/Entertainment/Images/img6.png"}
              heading={"Promote Your Events"}
              content={
                "Event Promotion:  Create event listings for concerts, screenings, performances, and more. Reach a targeted audience of entertainment enthusiasts and sell tickets directly through the platform."
              }
              content2={
                "Event Management Tools: Manage RSVPs, track attendance, and communicate with attendees seamlessly. Streamline your event planning process and ensure a successful event."
              }
              width={"350px"}
            />
            <WhyChooseCard
              imageSource={"/Assets/Entertainment/Images/img7.png"}
              heading={"Collaborate Effectively"}
              content={
                "Communication Tools: Stay connected with collaborators and clients through in-app messaging, voice calls, and video conferencing. Coordinate projects, share updates, and collaborate in real-time."
              }
              content2={
                "File Sharing and Collaboration: Share files, documents, and media assets with team members securely. Collaborate on projects, review feedback, and iterate quickly to deliver outstanding results."
              }
              width={"350px"}
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------Get started Component------------------------------------------- */}

      <section className={Styles.section_top9}>
        <div className="container">
          <div className={`${Styles.section_data9}`}>
            <h3>
              Get Started with <span>Connect</span> Today!
            </h3>
            <p>
              Join thousands of entertainment professionals who have chosen
              Connect as their go-to platform for networking, collaboration, and
              success in the entertainment industry.
            </p>
            <p>
              Sign up now to create your profile and unlock a world of
              opportunities!
            </p>
            <div className="text-center">
              <button className={Styles.section_btn9}>Lets Get Started</button>
            </div>
          </div>
        </div>
      </section>

      {/* ----------------------------------------------about opportunity---------------------------------------------------- */}

      <section
        className={Styles.section_top4}
        id="section5"
        ref={sectionRefs.section5}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            About Opportunities
          </h2>
          <h3 className={CommonCss.section_subHeading2}>
            Unlock Your Potential: Explore Endless Opportunities in
            Entertainment with Connect
          </h3>
          <p className={CommonCss.section_subHeading}>
            Welcome to Connect, your gateway to a world of exciting
            opportunities in the entertainment industry. Whether you're an
            aspiring actor, musician, filmmaker, or event planner, Connect
            offers a platform where your dreams can become reality. Here's how
            you can leverage the opportunities available on Connect:
          </p>
          <AboutOpportunities data={AboutOpportunitiesEntertainment} />
        </div>
      </section>

      {/* -----------------------------------------Our process --------------------------------------------------- */}

      <section
        className={CommonCss.section_top7}
        id="section6"
        ref={sectionRefs.section6}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Process
          </h2>
          <p className={CommonCss.section_subHeading}>
            Explore our comprehensive range of pet care services, including
            grooming, training, dog walking, pet adoption, and pet breeding.
            Each service is tailored to meet the diverse needs of pets and their
            owners.
          </p>
          <OurProcess data={EntertainmentOurProcessData} />
        </div>
      </section>

      {/* ----------------------------------------------FAQ---------------------------------------------------- */}

      <section
        className={Styles.section_top8}
        id="section7"
        ref={sectionRefs.section7}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            FAQs
          </h2>
          <p className={CommonCss.section_subHeading}>
            Answers to Common Inquiries About Entertainment Services.
          </p>
          <div className="text-center">
            <img
              src="/Assets/Entertainment/Images/img18.webp"
              className={CommonCss.section_top8_img}
              alt="..."
            />
          </div>
          <FAQsList
            color="#fff"
            backgroundColor="#FF6B00"
            data={EntertainmentFAQ}
          />
        </div>
      </section>

      {/* ----------------------------------------Footer and get started-------------------------------------- */}

      <section
        // className={Styles.section_top8}
        id="section8"
        ref={sectionRefs.section8}
      >
        <GetStarted
          heading={"Join the Entertainment Revolution"}
          para={`Whether you are a novice or an experienced professional in the entertainment industry, CONNECT provides the ideal platform to network, expand your horizons, and achieve success.`}
          para2={` Enroll in our community today and elevate your entertainment career to new heights. Uncover fresh prospects, foster enduring connections, and showcase your fervor to a global audience.`}
          image={"/Assets/Entertainment/Images/img19.webp"}
          smallImage={
            "/Assets/Entertainment/Images/enertainment_small_enquirenow_img.webp"
          }
          smallImageTop={"-35%"}
          bgColor={"#722391"}
          buttonBgColor={"#FF6B00"}
          buttonColor={"white"}
        />
        <Footer
          iconsBgColor="#FF6B00"
          contactColor="#FF6B00"
          iconsColor={"white"}
          handleNavClick={handleNavClick}
        />
      </section>
    </>
  );
};

export default EntertainmentLanding;
