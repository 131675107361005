import React, { useEffect, useRef, useState } from "react";

import { scroller } from "react-scroll";

import Styles from "./Healthcare.module.css";
import CommonCss from "../../Styles/CommonStyle.module.css";
import Header from "../../../Sections/Header/Header";
import WhyChooseCard from "../../../Components/WhyChooseCard/WhyChooseCard";
import OurServiceCard from "../../../Components/OurServiceCard/OurServiceCard";
import FactDisplaySection from "../../../Components/FactDisplaySection/FactDisplaySection";
import HealthcareLandingTop from "../../../Sections/LandingTop/heathcare/HealthcareLandingTop";
import Footer from "../../../Sections/Footer/Footer";
import GetStarted from "../../../Sections/GetStarted/GetStarted";
import AboutUs from "../../../Sections/AboutUs/AboutUs";
import OurProcess from "../../../Sections/OurProcess/OurProcess";
import FAQsList from "../../../Sections/FAQs/FAQsList";
import { HealthCareFAQ } from "../../../Sections/FAQs/FAQsListData";
import { AboutOpportunitiesHealthCare } from "../../../Sections/AboutOpportunities/AboutOpportunitiesData";
import AboutOpportunities from "../../../Sections/AboutOpportunities/AboutOpportunities";
import { HealthCareOurProcessData } from "../../../Sections/OurProcess/OurProcessData";
import { HealthCareAboutUsData } from "../../../Sections/AboutUs/AboutUsData";

const HealthcareLanding = () => {
  const [activeSection, setActiveSection] = useState("section1");

  const sectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      const domNode = ref.current;
      // console.log(domNode, " ref.current;");
      if (domNode) {
        observer.observe(domNode);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        const domNode = ref.current;
        if (domNode) {
          observer.unobserve(domNode);
        }
      });
    };
  }, [sectionRefs]);

  const handleNavClick = (section) => {
    // console.log(section);
    scroller.scrollTo(section, {
      duration: 100,
      delay: 0,
      smooth: "linear",
      offset: -50,
    });
  };

  return (
    <>
      {/* --------------------------------------Header----------------------------------------------- */}

      <Header
        handleNavClick={handleNavClick}
        activeSection={activeSection}
        primaryColor={" linear-gradient(0deg, #FF847C 0%, #FF847C 100%), #000"}
        secondaryColor={"#fff"}
        backgroundColor={
          "linear-gradient(0deg, rgba(76, 77, 220, 0.30) 0%, rgba(76, 77, 220, 0.30) 100%), #F8F9FF"
        }
      />

      {/* -------------------------------------Landing Top-------------------------------------- */}

      <section id="section1" ref={sectionRefs.section1}>
        <HealthcareLandingTop
          bgColor={"#F8F9FF"}
          tagColor={"#4C4DDC"}
          tagBgColor={"rgba(76, 77, 220, 0.10)"}
          heading={`Your Health, Our Promise of Excellence and Care`}
          headingColor="#05053D"
          para={`Connect offers personalized healthcare solutions, from home care
              to telemedicine, ensuring top-notch care with innovation and
              compassion. Trust us to enhance your health and quality of life.`}
          tag={`#1 solution for healthcare needs`}
          joinBtnC1={"#FF847C"}
          joinBtnC2={"#F9EEF2"}
          image={"/Assets/heroImages/healthcareHero.webp"}
          bgImage={`url('/Assets/BackgroundImages/HealthcareBackgroundImage.svg')`}
        />
      </section>

      {/* --------------------------------Fact display bar --------------------------------------------- */}

      <section>
        <FactDisplaySection
          color={"#fff"}
          backGroundColor={"#4C4DDC"}
          otherColor={"#FF847C"}
          lineColor={"#FCFCFC"}
          fact1={{ figure: 50, title: "Active Doctors" }}
          fact2={{ figure: 100, title: "Other service providers" }}
          fact3={{ figure: 1000, title: "Active User" }}
          fact4={{ figure: 50, title: "Active Pharmacy" }}
        />
      </section>

      {/* ------------------------------------About Us ---------------------------------- */}

      <section
        className={CommonCss.section_top1}
        id="section2"
        ref={sectionRefs.section2}
      >
        <AboutUs data={HealthCareAboutUsData} />
      </section>

      {/* -----------------------------------------Our Services---------------------------------- */}

      <section
        className={Styles.section_top2}
        id="section3"
        ref={sectionRefs.section3}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Services
          </h2>
          <p className={CommonCss.section_subHeading}>
            Discover the wide range of healthcare services we offer. From home
            care and nursing to telemedicine and emergency services, our
            comprehensive solutions cater to all your health and wellness needs.
          </p>
          <div className={CommonCss.containerSection}>
            <OurServiceCard
              imageSource={"/Assets/Healthcare/Images/img4.webp"}
              heading={"Home Care"}
              content={
                " Professional support services for safe, independent living at home."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Healthcare/Images/img5.webp"}
              heading={"Doctor Consultation"}
              content={
                "  Expert medical consultations and personalized healthcare solutions."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Healthcare/Images/img6.webp"}
              heading={"Nursing"}
              content={
                "Skilled nursing care enhancing patient comfort and recovery."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Healthcare/Images/img7.webp"}
              heading={"Telemedicine"}
              content={
                "Quick delivery of medicines and personal care at your doorstep."
              }
            />
            <OurServiceCard
              imageSource={"/Assets/Healthcare/Images/img8.webp"}
              heading={"Donor Registration"}
              content={"Help others by registering as a life-saving donor."}
            />
            <OurServiceCard
              imageSource={"/Assets/Healthcare/Images/img9.webp"}
              heading={"Ambulance"}
              content={
                " 24/7 emergency ambulance services for immediate assistance."
              }
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------Why to choose us--------------------------------------------- */}

      <section
        className={CommonCss.section_top3}
        id="section4"
        ref={sectionRefs.section4}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Why Choose us
          </h2>
          <p className={CommonCss.section_subHeading}>
            Discover the benefits of our comprehensive healthcare services. We
            prioritize convenience, efficiency, diversity, and transparency to
            provide you with the highest quality care. Learn why our platform is
            the preferred choice for all your healthcare needs.
          </p>
          <div className={CommonCss.containerSection}>
            <WhyChooseCard
              imageSource={"/Assets/Healthcare/Images/img10.png"}
              width={"350px"}
              heading={"Convenience"}
              content={
                "Access a wide range of healthcare services, from home care to telemedicine, all in one place. Our platform makes booking appointments easy, with 24/7 support."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Healthcare/Images/img11.png"}
              width={"350px"}
              heading={"Time-Saving"}
              content={
                "Our services are efficient and hassle-free. Expect quick responses and fast service delivery, allowing you to focus on what matters most."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Healthcare/Images/img12.png"}
              width={"350px"}
              heading={"Diversity"}
              content={
                "Choose from a variety of services tailored to your needs. Our network of experienced professionals caters to people of all ages and backgrounds."
              }
            />
            <WhyChooseCard
              imageSource={"/Assets/Healthcare/Images/img13.png"}
              width={"350px"}
              heading={"Transparency"}
              content={
                " We offer clear and upfront pricing with no hidden fees Stay informed with regular updates on your service requests and share your feedback to help us improve continually."
              }
            />
          </div>
        </div>
      </section>

      {/* --------------------------------------ABOUT OPPORTUNITY------------------------------ */}

      <section
        className={Styles.section_top4}
        id="section5"
        ref={sectionRefs.section5}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            About Opportunities
          </h2>
          <p className={CommonCss.section_subHeading}>
            Explore the various opportunities available with us. Whether you're
            a healthcare provider looking to join our platform or seeking ways
            to contribute to community health, find out how you can get
            involved.
          </p>
          <AboutOpportunities data={AboutOpportunitiesHealthCare} />
        </div>
      </section>

      {/* ---------------------------------------------OUR PROCESS ------------------------------------- */}

      <section
        className={CommonCss.section_top7}
        id="section6"
        ref={sectionRefs.section6}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            Our Process
          </h2>
          <p className={CommonCss.section_subHeading}>
            Learn about our streamlined process designed to provide you with the
            best healthcare experience. From onboarding skilled professionals to
            post-service support, we ensure quality at every step.
          </p>
          <OurProcess data={HealthCareOurProcessData} />
        </div>
      </section>

      {/* --------------------------------------FAQ--------------------------------------------- */}

      <section
        className={Styles.section_top8}
        id="section7"
        ref={sectionRefs.section7}
      >
        <div className={CommonCss.container}>
          <h2
            className={`${CommonCss.heading} ${Styles.section_heading_color} `}
          >
            FAQs
          </h2>
          <p className={CommonCss.section_subHeading}>
            Find answers to common questions about our healthcare services.
          </p>
          <div className="text-center">
            <img
              src="/Assets/Healthcare/Images/img20.webp"
              className={CommonCss.section_top8_img}
              alt="..."
            />
          </div>

          <FAQsList
            color="#fff"
            backgroundColor="#FF847C"
            data={HealthCareFAQ}
          />
        </div>
      </section>
      <section
        // className={Styles.section_top8}
        id="section8"
        ref={sectionRefs.section8}
      >
        <GetStarted
          heading={"Start Your Journey to Better Health Today!"}
          para={`Join us in revolutionizing healthcare with our personalized and
                convenient services. Whether you need home care, telemedicine,
                or specialist consultations, we're here to support your health
                and well-being. Get started now and experience top-quality care
                at your fingertips.`}
          image={"/Assets/Healthcare/Images/img21.webp"}
          smallImage={
            "/Assets/Healthcare/Images/healthcare_small_enquirenow_img.webp"
          }
          smallImageTop="-20%"
          bgColor={"#4C4DDC"}
          buttonBgColor={"#FF847C"}
          buttonColor={"white"}
        />
        <Footer
          contactColor="#FF847C"
          iconsColor={"white"}
          iconsBgColor="#FF847C"
          handleNavClick={handleNavClick}
        />
      </section>
    </>
  );
};

export default HealthcareLanding;
